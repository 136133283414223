import React from "react";
import { useEffect, useRef, useState } from "react";
import ReactDom from "react-dom";
import ReactHtmlParser from "react-html-parser";
import PlayerConstants from "../../utils/PlayerConstants";
import HeaderTopic from "../../components/ui/HeaderTopic/HeaderTopic";
import { useSelector, useDispatch } from 'react-redux';
import ContentTracking from "../../core/progress/ContentTracking";
import DataHelper from "../../utils/DataHelper";
import * as courseTrackingAction from '../../redux/actions/courseTrackingAction';
import { useHistory} from 'react-router-dom';
import BuildHelper from "../../utils/BuildHelper";
import useToastNotifications from '../../utils/hooks/useToastNotifications';

const TemplateRender = ({ topicInfo, eventHandlers, trackingInfo }) => {
    const tempref = useRef();
    const dispatch = useDispatch();
    const {userInfo, utility} = useSelector(state => state);
    const courseInfo = useSelector(state => state.courseInfo);
    const courseEdit = useSelector(state => state.courseEdit);
    const courseTracking = useSelector(state => state.courseTracking);
    const globalStyle = useSelector(state => state?.globalStyle);
    const [gStyle, setGStyle] = useState(globalStyle);
    const history = useHistory();
    const { showWarning } = useToastNotifications();
    const [localMarkup, setLocalMarkup] = useState(topicInfo.markup.replace(',', ''));
    const { mb, mt, pl, pr, gfs, isfs } = { ...gStyle?.comspace };
    try{
        if (topicInfo?.lrncontent === null || (Object.keys(topicInfo)?.length > 0 && topicInfo?.lrncontent[1]?.name === 'AudioPlayer')) {
            topicInfo.lrncontent = PlayerConstants?.SIMPLE_TOPIC_CONTENT;
        }
   }catch(e){}

    const findTemplateName = (fname) => {
        let name = fname.name;
        return require(`../../components/content/${name}/${name}`).default
    }

    /** Topic inside each component progress */
    const findComponentProgress = (cmp) => {
        let prgr = cmp?.props?.track || {};
        // let prgr = new ContentTracking(`${cmp.compid+":"+0}`,0,{})
        let comp = trackingInfo?.content.find(item => {
            let id = item.id.substr(item.id.indexOf(':') + 1)
            if (Number(id) === Number(cmp.compid)) {
                prgr = item;
            }
        });
        // set prgr.id if not available, based on component name 
        if(!prgr?.id)prgr.id= PlayerConstants.getComponentByName((cmp?.name ?? comp?.cname))?.id +
            ":" +cmp?.compid;

        return prgr;
    }
 
    /*
    * quiz configuration functionally
    * resetCourse
    * handleRoute
    * lockCourse
    * 
    */
    // const resetCourse=()=>{
    //     try{
    //         if(courseTracking){
    //            let QuizLRN= DataHelper.getAllLRNCourseTrack(courseInfo?.flatten)
    //            let progressed = DataHelper.retakeProgressData(courseTracking.flatten,QuizLRN);
    //            courseTracking.courseTracking={...progressed?.t}
    //            topicInfo?.lrncontent?.forEach((e) => {
    //             const compidNumber = Number(e?.compid);
    //             const obj = QuizLRN?.find((q) => {
    //                 const qId = Number(q?.split(":")[0]); // Assuming QuizLRN contains IDs like "id:state"
    //                 return qId === compidNumber;
    //             });
            
    //             if (obj) {
    //                 e.props.track.state = { ...obj };
    //             }
    //         });
    //            dispatch(courseTrackingAction.saveCourseProgress(courseTracking));
    //         }
    //     }catch(e){}
    // }

    const handleRoute=(key)=>{
        history.push( `${key}` );
    }

    // const lockCourse=()=>{
    //     let progress={
    //        ...courseTracking,
    //     courseTracking:{
    //         ...(courseTracking?.courseTracking||{}),
    //         lock_course:true  
    //     },
    //     }
    //     dispatch(courseTrackingAction.saveCourseProgress(progress));
    // }


    // // type 0 sendQuizAttemptMail every fail || 1 quizAttemptsList --complete attempt fail
    // const sendQuizReport=(payload)=>{
    //     let postData = { 
    //         id:payload?.tId||0,
    //         res:payload?.res||"",
    //         send_respmail:payload?.config?.validation?.fail?.rule?.send_respmail?.value,
    //         send_atmptmail_touser:payload?.config?.validation?.fail?.rule?.send_atmptmail_touser?.value,
    //         uid:userInfo?.uid||56797,
    //         cname:window?.ce?.rlm?.config_id||"4917"
    //      }
    //      if(payload?.type===1){
    //         postData={
    //             ids:payload?.config?.validation?.mail||""
    //         };
    //      }
    //      let type=payload?.type||0;   
    //      dispatch(courseTrackingAction.sendQuizAttemptMail({postData,type}));
    // }


    const compCustomization = (comp,lrn) => {
        let cmpProps = { ...comp };
        switch (comp?.cename) {
            case "Quizzes":
                const nonCustomized=DataHelper.hasCustomized(lrn);
                /** 
                 * If the build is not configured, set the max_select property 
                 * to the length of items quiz
                 */
                if(!BuildHelper.isConfig())cmpProps['max_select']= cmpProps?.items?.length;
                /** 
                 * configured
                 * Check if the 'pers' property is neither 1 nor 3, 
                 * or if it's not defined, and if there are no customized settings 
                 */
                else if (((Number(cmpProps.pers) !== 1 && Number(cmpProps.pers) !== 3) || !cmpProps?.pers) && !nonCustomized) {
                    cmpProps["config"] = globalStyle?.lrnSettings[cmpProps?.cename || '']?.config || {};
                }
                /** 
                 * If there are customized settings and the build is configured, 
                 * set the globalSettings property based on global styles for the specified component name 
                 */
                if(!nonCustomized && BuildHelper.isConfig())cmpProps['globalSettings']=globalStyle?.lrnSettings[cmpProps?.cename || '']
                return {
                    ...cmpProps,
                    resetCourse: () =>DataHelper.resetCourse(courseTracking,dispatch,topicInfo,courseTrackingAction,courseInfo),
                    sendQuizReport: (payload) =>DataHelper.sendQuizReport(payload,courseTrackingAction,dispatch,userInfo),
                    reDirect:(path)=>handleRoute(path),
                    lockCourse:()=>DataHelper.lockCourse(dispatch,courseTracking,courseTrackingAction),
                };
                case "ScenarioBlock":
                    return {
                        ...cmpProps,
                        // tp_name:topicInfo?.title||'',
                        ft:gfs,
                        author: userInfo?.user?.firstname || window?.ce?.ceuser?.firstname || "Test User",
                    }
                case "VideoHotspot":
                case "Video":
                    // config & not customised  --- We are displaying the cue add action for configurations that are customized 
                    cmpProps["hasCue"] =!(DataHelper.hasCustomized(lrn)&&BuildHelper.isConfig());
                    return {
                        ...cmpProps,
                        forward_rewind:cmpProps?.forward_rewind ?? globalStyle?.ff_r,
                        userInfo:userInfo,
                        language: courseInfo?.transition?.[BuildHelper?.defaultLanguage?.() || 'en_US']?.['LANGUAGE_N'] || 'English'
                    }
                case "ArchiveVideo":
                    // config & not customised  --- We are displaying the cue add action for configurations that are customized 
                    cmpProps["hasCue"] = !(DataHelper.hasCustomized(lrn)&&BuildHelper.isConfig());
                return {
                    ...cmpProps,
                    forward_rewind:globalStyle?.ff_r,
                }
                case "ReorderingList":
                case "DragandMatch":
                    // config & not customised  --- We are displaying the cue add action for configurations that are customized 
                    cmpProps["showWarning"] =showWarning;
                return {
                    ...cmpProps,
                    forward_rewind:globalStyle?.ff_r,
                }

            default:
                return cmpProps;
        }
    }
    

    const moduleRender =(cmprndr, el) => {
        try {
            // only testing puposes on local otherwise comment this 
            let ImportedCom = findTemplateName(cmprndr);
            let compTrack = findComponentProgress(cmprndr);
            el.id = cmprndr?._id;
            /**
             * When the learning is scroll-based and the topic is visited with topicInfo?.status as 0,
             * we mark the learning as completed.
             */
            let status=1;
            //((topicInfo?.lrncontent?.length < 3) ? topicInfo?.status : -1)
            let cmpProps = Object.assign(cmprndr.props, { track: compTrack, handler:(d)=>{eventHandlers({...d,name:cmprndr['name']})}, cename:cmprndr['name'], topic_id: cmprndr._id, tp_status:status })
            // if(cmpProps?.cename==="Quizzes"){
            //     if((Number(cmpProps.pers)!==1 && Number(cmpProps.pers)!==3) || !cmpProps?.pers){
            //        cmpProps["config"]=globalStyle?.lrnSettings[cmpProps?.cename||'']?.config||{}
            //     }
            //      cmpProps={
            //          ...cmpProps,
            //          globalSettings:globalStyle?.lrnSettings[cmpProps?.cename||''],
            //          resetCourse:()=>{resetCourse()},
            //          sendQuizReport:(payload)=>{sendQuizReport(payload)},

            //      }
            //   }
            //  if(cmpProps?.cename==="ScenarioBlock"){
            //     cmpProps={
            //         ...cmpProps,
            //         // tp_name:topicInfo?.title||'',
            //         ft:gfs,
            //         author: userInfo?.user?.firstname || window?.ce?.ceuser?.firstname || "Test User",
            //     }
            //  }
            cmpProps = compCustomization(cmpProps,cmprndr );
            ReactDom.render(<ImportedCom {...cmpProps} />, el)
        } catch (e) {
            console.debug(e.name, '::', e.fileName, '::', e.lineNumber, '::', e.message)
        }
    }


    const injectComponent = (compRef, el, i, compRefId) => {
        if (compRef) {
            try {
                if (compRefId && compRefId.info === compRef) {
                    console.log('--------')
                    console.log('compRef', compRef)
                    console.log('el', el)
                    console.log('i', compRefId)
                    console.log('compRefId', compRefId)
                    findTemplateById(el, i, compRef, compRefId);
                    return
                }

                findTemplateById(el, i, compRef, compRefId);
            } catch (e) {
                console.debug('exception raised / component injection failed', e)
            }
        }
    }


    const findTemplateById = (el, i, compRef, compRefId) => {
        try {
            let compId = compRef.split('_')[1];
            const cmprndr = topicInfo.lrncontent.find((item, index) => { return Number(item?.compid) === Number(compId) })
            if (cmprndr && !compRefId) {
                moduleRender(cmprndr, el)
            }
            else if (compRefId?.id) {
                moduleRender(cmprndr, el)
            }
        } catch (e) {
            console.debug("Error in split area", e)
        }

    }

    useEffect(() => {
        if (tempref.current) {
            const compRefId = courseEdit.target?.element?.getAttribute('id');
            const compRef = courseEdit.target?.cmp;
            const colls = tempref.current.children;
            const elms = [...colls];
            moduleRender(courseEdit.itemInfo, courseEdit.target?.element)

        }
    }, [courseInfo]);


    useEffect(() => {
        if (tempref.current) {
            // eventHandlers({ type: "SAVE" })
            const colls = tempref.current.children;
            const elms = [...colls];
            elms.forEach((el, index) => {
                const compRef = el.getAttribute('compId');
                const cmprndr = topicInfo.lrncontent.find((item, index) => { return Number(item?.compid) === Number(compRef.replace("com_", "")) })
                if (cmprndr) {
                    let atId = topicInfo.nodeId + '_' + topicInfo.id;
                    el.setAttribute("id", atId);
                    el.classList.add('scrollable-section');
                    injectComponent(compRef, el, index)
                }
            })
        }
    }, [localMarkup,globalStyle,utility?.isCloned]);

    useEffect(() => {
    try{
        let splitData = topicInfo?.markup?.replace(/,/g, "").split("</section>");
        let temp = []
        if (!splitData[splitData.length - 1]?.trim()) {
            temp = splitData?.slice(0, splitData?.length - 1)
        } else {
            temp = splitData
        }
        let isRemove=[];
        let tempMarkup = temp.filter((item, index) => {
            let markupSplit=item?.split(',');
            const index2 = item.lastIndexOf("com_");
            const index3 = item.lastIndexOf(">");
            let result = item.slice(index2 + 4, index3 - 1);
            if(markupSplit?.length > 1) isRemove?.push(index);
            return topicInfo.lrncontent.find((item, index) => { return Number(item?.compid) === Number(result) })
        })
      if(isRemove?.length > 0)tempMarkup= tempMarkup?.map(el=>{
            let markup=el;
            const splitMarkup=el?.split(',');
            if(splitMarkup?.length > 1) markup=splitMarkup[1]||markup;
            return markup
        });
        
        // adding the last element closing '</section>' tag
        if (tempMarkup && tempMarkup?.length > 0) tempMarkup[tempMarkup?.length - 1] += '</section>';

        setLocalMarkup(tempMarkup.join('</section>'));
    }catch(e){}
    }, [topicInfo,utility?.isCloned])

    // const checkProgress = () => {
    //     eventHandlers({ t: topicInfo, p: 90 })
    // }

    /* Global Styleing */
    useEffect(() => {
        setGStyle(globalStyle);
    }, [globalStyle]);


    // const { mb, mt, pl, pr, gfs, isfs } = { ...gStyle?.comspace };//gfs-${getGlobalFontSize(gfs)}
    let className =`smt-${mt} smb-${mb} spl-${pl} spr-${pr} ${!globalStyle.isInstruction && 'hide-instruction'} markup-render tmpl-item-` + topicInfo?.templateid
    if(isfs) className= className + ` gfs`
    //console.log('topicInfo::', topicInfo)
    //console.log('localmarkup', localMarkup)

    return <div className='main-layout-container'>
        <HeaderTopic />
        {(localMarkup && !localMarkup?.includes(","))  ? <div ref={tempref} className={className} >
            {ReactHtmlParser(localMarkup)}
        </div> : null}
    </div>
}

export default TemplateRender;
