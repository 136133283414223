import React, { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import UploaderPanel from '../UploaderPanel/UploaderPanel';
import Acc from '@material-ui/core/Accordion';
import { FormControlLabel } from '@material-ui/core';
import { AddCircle } from '@material-ui/icons';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import TextField from '@material-ui/core/TextField';
import CheckIcon from '@material-ui/icons/Check';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import DataHelper from '../../../utils/DataHelper';
import Checkbox from '@mui/material/Checkbox';
import PropTypes from 'prop-types'
import { getPlaceHolderImg } from "../../../utils/MediaConfig";
import PlayerConstants from '../../../utils/PlayerConstants';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@material-ui/core/Button';
import ResizableTextArea from '../../../components/ui/ResizableTextArea/ResizableTextArea';


const DraggablesPanel = ({ question, head, subhead, explanation,feedback, bucket, drags, handleProperties, handleCeAssets, cename, id, enable_voice, enable_strict }) => {

    const [data, setData] = useState({ question, head, subhead, feedback, explanation, bucket, drags, enable_voice, enable_strict})
    const [expanded, setExpanded] = useState(0);
    const [childExpanded, setChildExpanded] = useState(0);
    const [editMode, setEdit] = useState(null);
    const [dragItems, setDragItems] = useState(drags);
    const [isExplanation, setIsExplanation] = useState(false);
    const [DropContainer, setDropContainer] = useState(bucket);
    let max_count = 0
    const getMaxDropbox = Object.values(PlayerConstants.COMPONENTS_LIST).filter((el) => el.name === cename)
    max_count = getMaxDropbox[0]?.max_count;
    const max_inner_count=6;



    useEffect(() => {
        handleProperties(data)
    }, [data]);


    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
        setEdit(null)
    };

    const handleChildAcc = (panel) => (event, isExpanded) => {
        setChildExpanded(isExpanded ? panel : false);
        setEdit(null)
    };

    const handleDeleteDrop = (event, id) => {
        if (DropContainer.length !== 1) {
            const removed = DropContainer.filter((e, i) => e?.id !== id)
            const removedDrag = dragItems.filter((e, i) => e?.target !== id)
            setDropContainer(removed);
            setDragItems(removedDrag)
            setData({ ...data, bucket: removed, drags: removedDrag });
            handleProperties(data)
        }
        event.stopPropagation()
    }


    const onEnable = (e) => {
        setData({ ...data, [e.target.name]: e.target.checked , drags:[...data?.drags], bucket:[...data?.bucket]});
        handleProperties({...data,[e.target.name]: e.target.checked ,drags:[...data?.drags]})
    }

    const onEnableFeedBack = (e) => {
        const updatedData={ ...data, [e.target.name]: e.target.checked ,enable_strict:e.target.checked ? false :data?.enable_strict, drags:[...data?.drags]}
        setData({ ...updatedData});
        handleProperties({...updatedData})
    }


    const handleChangeDropInput = (e, index) => {
        DropContainer[index] = { ...DropContainer[index], [e.target.name]: e.target.value }
        // setDropContainer(newBucket)
        setData({ ...data, bucket: DropContainer });
        handleProperties(data)
    }


    const handleImageMedia = (media, index) => {
        try {
            const updatedItem=[...dragItems];
            updatedItem[index] = { ...updatedItem[index], image: media.name }
            handleCeAssets(drags[index]?.image, media?.name, { ...data, drags: updatedItem });
            setData({ ...data, drags: [...updatedItem] });
            handleProperties(data);
        } catch (e) {}
    }


    const handleAudioMedia = (media, index) => {
        try {
            const updatedItem=[...dragItems];
            updatedItem[index] = { ...updatedItem[index], audio: media.name }
            handleCeAssets(drags[index]?.audio, media?.name, { ...data, drags: updatedItem });
            setData({ ...data, drags: updatedItem });
            handleProperties(data);
        } catch (e) {}
    }


    const handleChangeDragInput = (e, index) => {
        dragItems[index] = { ...dragItems[index], [e.target.name]: e.target.value }
        setData({ ...data, drags: [...dragItems] });
        handleProperties({ ...data, drags: [...dragItems] })
    }

    const handleFeedBack = (e, index) => {
        let updatedFeedback=[...(data?.drags[index]?.feedback||[])];
        updatedFeedback[e.target.name]=e.target.value
        dragItems[index] = { ...dragItems[index], feedback:updatedFeedback};
        handleProperties({ ...data, drags: [...dragItems]})
        setData({ ...data, drags: [...dragItems]});
    }

    const handleTargetChange = (e, index) => {
        const currentTGT=dragItems?.filter(e=>e?.target === e?.target?.value)
        if(currentTGT?.length < max_inner_count){
            dragItems[index] = { ...dragItems[index], [e.target.name]: e?.target?.value }
            setData({ ...data, drags: dragItems });
            handleProperties(data)
        }
    }

    const generateUniqueId = () => {
        try {
            const randomNum = Math.random().toString(16).substring(2);
            const uniqueId = randomNum;
            return uniqueId;
        } catch (e) { }
    }

    const addDropContainer = () => {
        if (DropContainer?.length < max_count) {
            let id = Math.max(...DropContainer?.map(item => item?.id), 0)||1;
            if(id < DropContainer?.length-1) id=DropContainer?.length+1;
            let newDrop = {
                id: id+1,
                container: [],
                text: `DBox ${DropContainer?.length+1}`,
            }
            DropContainer.push(newDrop);
            setData({ ...data, bucket: [...DropContainer] });
            handleProperties({ ...data, bucket: [...DropContainer] })
        }
    }

    const addNewDrag = (target) => {
    const currentTGT=dragItems?.filter(e=>e?.target === target)
    if(currentTGT.length < max_inner_count){
        let id = generateUniqueId();
        let newDrag = {
            "id": id,
            "target": target,
            "audio": "",
            "image": "",
            "text": "DCT",
            'feedback':['Correct.','Sorry, that’s not correct.']
        }
        dragItems.push(newDrag);
        setData({ ...data, drags: [...dragItems] });
        handleProperties({ ...data, drags: [...dragItems] })
    }
    }


    const handleSave = (e, index) => {
        setEdit(null)
        e.stopPropagation()
    }

    const handleEdit = (e, index) => {
        setEdit(index)
        e.stopPropagation()
    }

    const getBgImageUrl = (path) => {
        return DataHelper.getResourcePath(0, path);
    }

    const handleDeleteDrags = (event, item) => {
        if (dragItems.length !== 1) {
            const removedDrag = dragItems.filter((e, i) => e?.id !== item?.id)
            setDragItems(removedDrag);
            setData({ ...data, drags: removedDrag });
            handleProperties(data)
        }
        event.stopPropagation()
    }
    const handleExplanation = () => {
        setIsExplanation(!isExplanation)
    }

    const handleRightWrong = (e) => {
        let explanationObj={
            ...explanation,
            [e.target.name]: e.target.value
        }
        setData({ ...data, explanation: explanationObj });
        handleProperties(data);
    }

    const handleQuestion =(e)=>{
        setData({ ...data, [e.target.name]: [e.target.value] });
        handleProperties(data);
    }


    return (
        <div className='accordion-prop-container dragging-prop-container'>
            {isExplanation ?
                <div className='info-box'>
                    <div className='title-label'>
                        <p className='label'>Explanation</p>
                        <Button onClick={handleExplanation} className='close-explanation'><CloseIcon />Close</Button>
                    </div>
                    <div className='row'>
                        <div className='col-sm-12 col-md-12 col-lg-12'>
                            <label className='label'>Right Answer</label>
                            <textarea id="outlined-basic" rows="3" className='w-100'
                                spellCheck={false}  label="Drag Content"
                                value={explanation?.right}
                                name='right'
                                onChange={(e) => handleRightWrong(e)}
                                placeholder="Enter your Explanation"
                            />
                        </div>
                        <div className='col-sm-12 col-md-12 col-lg-12'>
                            <label className='label'>Wrong Answer</label>
                            <textarea id="outlined-basic" rows="3" className='w-100'
                                spellCheck={false} name='wrong' label="Drag Content"
                                value={explanation?.wrong}
                                onChange={(e) => handleRightWrong(e)}
                                placeholder="Enter your Explanation"
                            />
                        </div>
                    </div>
                </div>
                : <>
                <div className='draggable-global-property'>
                <textarea  id="outlined-basic" className='draggable-heading'  spellCheck={ false } name='question'  defaultValue={data.question ||'Drag the item' }  rows="1"  onClick={ ( e ) => e.stopPropagation() } onChange={ ( e ) => handleQuestion(e) } />
                    <div className='label-check'>
                        <FormControlLabel label={'Enable VoiceOver Explanation'} className="label" labelPlacement="start" name="enable_voice" control={<Checkbox checked={data?.enable_voice} onChange={(e) => onEnable(e)} />} />
                        <FormControlLabel label={'Show Explanation'} className="label" labelPlacement="start" name="feedback" control={<Checkbox checked={data?.feedback} onChange={(e) => onEnableFeedBack(e)} />} />
                        <FormControlLabel label={'Strict Mode'} className="label" labelPlacement="start" name="enable_strict" control={<Checkbox checked={data?.enable_strict} onChange={(e) => onEnable(e)} />} />
                    </div>
                </div>
                    <div className={``}>
                        {
                            DropContainer?.map((el, index) => {
                                return <Acc key={index} expanded={expanded === index} onChange={handleChange(index)}>
                                    <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
                                        {editMode === index ? <>
                                            <TextField id="outlined-basic" className='list-title' spellCheck={false} name='text' defaultValue={el.text} onClick={(e) => e.stopPropagation()} onChange={(e) => handleChangeDropInput(e, index)} />
                                            <CheckIcon className='save-prop-accordion' onClick={(e) => handleSave(e, index)} />
                                        </> : <>
                                            <Typography >{el.text}</Typography>
                                            <EditIcon className='edit-prop-accordion' onClick={(e) => handleEdit(e, index)} />
                                            <DeleteIcon className='delete-prop-accordion' onClick={(e) => handleDeleteDrop(e, el?.id)} />
                                        </>}
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        {/* Inner continuer Drag list inside the drop  accordion Start */}

                                        <div className='inner-accordion-continuer'>
                                            <div className={``}>
                                                {
                                                    dragItems.length > 0 && dragItems.map((item, idx) => {
                                                        let rightAnswer=item?.feedback&&item?.feedback[0];
                                                        let wrongAnswer=item?.feedback&&item?.feedback[1];

                                                        return (item?.target === el?.id) && <Acc key={idx} expanded={childExpanded === idx} onChange={handleChildAcc(idx)}>
                                                            <AccordionSummary className='inner-summary' aria-controls="panel1a-content" id="panel1a-header" >
                                                                {<><div className='circle-preview-img-box'>{item?.image && <img className='preview-item-img' src={getBgImageUrl(item?.image)} onError={(e) => { e.target.onerror = null; e.target.src = getPlaceHolderImg(200) }}
                                                                    alt='drag-item' />}</div><Typography className='drag-text' >{item.text}</Typography>
                                                                    <FormControl className='drag-target' variant="standard">
                                                                        <InputLabel className='target-label' id="demo-simple-select-standard-label">Target</InputLabel>
                                                                        <Select
                                                                            labelId="demo-simple-select-standard-label"
                                                                            id="demo-simple-select-standard"
                                                                            name="target"
                                                                            value={item?.target}
                                                                            onChange={(e) => handleTargetChange(e, idx)}
                                                                            label="Target"
                                                                        >
                                                                            {DropContainer.map((item, mIdx) => <MenuItem key={mIdx} className='select-target-option-list' disabled={item.text.trim().length === 0} value={item?.id}>{item?.text}</MenuItem>)}
                                                                        </Select>
                                                                    </FormControl>
                                                                    <DeleteIcon className='delete-prop-accordion' onClick={(e) => handleDeleteDrags(e, item)} /></>
                                                                }
                                                            </AccordionSummary>
                                                            <AccordionDetails>
                                                                <div className='row' key={childExpanded + '_' + item.id}>
                                                                    <div className='col-sm-12 col-md-12 col-lg-12'>
                                                                        <label className='label'>Drag Content</label>
                                                                        <textarea id="outlined-basic" rows="3" className='w-100'
                                                                            spellCheck={false} name='text' label="Drag Content"
                                                                            value={item.text}
                                                                            onChange={(e) => handleChangeDragInput(e, idx)}
                                                                            placeholder="Enter your Drag Content"
                                                                        />
                                                                    </div>
                                                                    {data?.feedback &&  <div className='col-sm-12 col-md-12 col-lg-12'>
                                                                            <div className='d-flex flex-wrap  my-3'>
                                                                                <div className='flex-1 w-50'>
                                                                                 <label className='label'>Right Explanation</label>
                                                                                 <ResizableTextArea style={{width:'99%'}} name={0} placeholder="Right Explanation" value={rightAnswer} onChange={(e)=>handleFeedBack(e, idx)}  />
                                                                                </div>
                                                                                <div className='flex-1  w-50'>
                                                                                <label className='label'>Wrong Explanation</label>
                                                                                <ResizableTextArea style={{width:'99%'}} name={1} placeholder="Wrong Explanation" value={wrongAnswer}  onChange={(e)=>handleFeedBack(e, idx)} />
                                                                                </div>
                                                                            </div>
                                                                        </div>}
                                                                    <div className={data?.enable_voice ? `col-md-12 col-lg-6  small-uploader` : 'col-md-12 col-lg-12'}>
                                                                        <label className='label'>Browse Image</label>
                                                                        <UploaderPanel accept={'image'} fileName={item?.image} name={`${id}_${expanded}_${idx}`} setUploadedData={(el) => handleImageMedia(el, idx)} />
                                                                    </div>
                                                                    {data?.enable_voice && <div className='col-sm-6 col-md-6 col-lg-6 small-uploader'>
                                                                        <label className='label'>Browse Audio</label>
                                                                        <UploaderPanel accept={'audio'} fileName={item?.audio} name={`${id}_${expanded}_${index}`} setUploadedData={(el) => handleAudioMedia(el, idx)} />
                                                                    </div>}
                                                                </div>
                                                            </AccordionDetails>
                                                        </Acc>
                                                    })}
                                                <br />
                                                <div className='add-new-slide' title={'Add Option'} onClick={() => addNewDrag(el.id)}><AddCircle className='add-accordion' /> Add New Drag </div>
                                            </div>
                                        </div>

                                        {/* Inner continuer Drag list inside the drop  accordion End */}
                                    </AccordionDetails>
                                </Acc>
                            })
                        }
                        <div className='add-box'>
                            <div className='add-new-slide' title={'Drop box'} onClick={addDropContainer}><AddCircle className='add-accordion' /> Add New Drop box</div>
                            <div className='explanation' title={'Explanation'} onClick={handleExplanation}><EditIcon />Explanation</div>
                        </div>
                    </div>
                </>
            }
        </div>
    )
}

DraggablesPanel.propTypes = {
    /** bucket will have number of drag objects  */
    bucket: PropTypes.array,
    /** drags items will be object contains list of items for drag and drop */
    drags: PropTypes.array,
    /** enable_voice manage the voice option */
    enable_voice: PropTypes.bool,
    /** string it will contain the question  */
    question: PropTypes.string,
    /** head it will contain the head question  */
    head: PropTypes.string,
    /** subhead it will contain the subhead question  */
    subhead: PropTypes.string,
    /** explanation it will contain the question  explanation */
    explanation: PropTypes.object,
    handleProperties: PropTypes.func,
    /** handleCeAssets - This function handles the CE Library asset usage update actions ( previousAsset | newAsset | updatedProps ). */
    handleCeAssets: PropTypes.func,

}

export default DraggablesPanel