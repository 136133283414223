import React, { useState, useEffect} from 'react'
import './addonoverlay.scss'
import Button from '@material-ui/core/Button';
import Close from '@material-ui/icons/Close';
import Celogo from '../../../assets/img/ce_logo_skill_comp.png'
// import { useSelector } from 'react-redux';
// import RichTextEditor from '../../../editor/components/RichTextEditor/RichTextEditor';


const OverLayRender = ({ data, bgcolor, close, type, userInfo }) => {
    // const [isOverLay, setOverLay] = useState(false)

    /** Test data  
     * Dummy Need to be removed
    */
    // if (data['meta_data']) {
    //     data['title'] = '<h5>Lorum Ipsum</h5>'
    //     data['content'] = '<p>' + data['meta_data'] + '</p>'
    // }
    // const userInfo = useSelector(state => state.userInfo);
    const [logoPath, setLogoPath] = useState(Celogo);
    useEffect(() => {
        try{
            const clientId = userInfo?.user?.pvtid 
            const fileExtension = userInfo?.user?.logo ? userInfo?.user?.logo.split(".").pop() : 'png';
            if(clientId && fileExtension){
                    setLogoPath('https://dg7zdxz92ywv.cloudfront.net/platform/clientlogo/'+clientId+'.'+fileExtension)
            }
        }catch{}
    }, [userInfo])
    const [render, setRender] = useState(true);
    const closeRenderArea = () => {
        close();
        setRender(false)
    }

    const getTitle = () => {
        if (!data) {
            return 'Title Goes Here'
        }
        if (data?.title) {
            return data?.title
        }
    }

    const getContent = () => {
        if (!data) {
            return 'Content Goes Here'
        }
        if (data?.description) {
            return data?.description
        }
    }

    return render && <div className='overlay-container'>
        <div className='overlay-render'></div>
        <div className="add-on-overlay" style={{ backgroundColor: bgcolor }}>
            <div className='ce-logo'>
                <img src={logoPath} alt='logo' />
            </div>
            <div className="head-item">
                <div className='overlay-title' dangerouslySetInnerHTML={{ __html: getTitle() }}></div>
                {/* <Button className="preview-cue-btn" onClick={()=>setOverLay(!isOverLay)}>{isOverLay ? 'edit' : 'Preview'}</Button>
                <Button className="save-cue-btn">Save</Button> */}
                <Close className="close-btn" onClick={() => closeRenderArea()} />
            </div>
            {type != 5 && <div className="content-item" dangerouslySetInnerHTML={{ __html: getContent() }}>
            </div> }
             {/* {type != 5 && isOverLay ? <div className="content-item" dangerouslySetInnerHTML={{ __html: getContent() }}>
             </div> : <RichTextEditor data={getContent()}/>} */}
        </div>
    </div>
}

export default OverLayRender;