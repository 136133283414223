import React from 'react';
import Popover from '@mui/material/Popover';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';

/**
 * ReusablePopover component to display a customizable popover.
 *
 * @param {Object} props - The component props.
 * @param {string} props.id - The ID of the popover.
 * @param {Object} props.anchorEl - The HTML element that the popover is anchored to.
 * @param {Function} props.onClose - Function to call when the popover should be closed.
 * @param {Function} props.handleConfirm - Function to call when the confirm button is clicked.
 * @param {boolean} props.open - Boolean indicating whether the popover is open.
 * @param {string} [props.message] - The message to display in the popover. If `PopoverContent` is provided, this is ignored.
 * @param {string} props.cancelText - The text for the cancel button.
 * @param {string} props.confirmText - The text for the confirm button.
 * @param {React.ElementType} [props.PopoverContent] - Optional custom content component for the popover.
 * @returns {JSX.Element} The rendered ReusablePopover component.
 */

const CustomPopover = ({ id, anchorEl,className, onClose, handleConfirm, open, message, cancelText, confirmText }) => {
    return (
        <Popover
          id={id}
          className={className}
          open={open}
          anchorEl={anchorEl}
          onClose={onClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <div className='sweet-alert popover'>
            <p>{message}</p>
            <div className='popover-btn'>
              <Button className='btn-link' onClick={onClose}>{cancelText}</Button>
              <Button className='btn btn-lg btn-primary' onClick={handleConfirm}>{confirmText}</Button>
            </div>  
          </div>
        </Popover>
      );
    };
    
    CustomPopover.propTypes = {
      popId: PropTypes.string,
      anchorEl: PropTypes.object,
      onClose: PropTypes.func,
      handleConfirm: PropTypes.func,
      open: PropTypes.bool,
      message: PropTypes.string,
      cancelText: PropTypes.string,
      confirmText: PropTypes.string,
    };

export default CustomPopover