import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { WorldMap } from "react-svg-worldmap";
import useForceUpdate from "use-force-update";
import './maptoplay.scss'
import { pathCreater } from '../../../utils/MediaConfig'

/**
 * 
 * @param {*} mapData 
 * @returns array of plotpoints object which we can map the country
 */


/**
 * 
 * @param {*} title 
 * @returns returns the name of country
 */

/**
 * MapToPlay component will render the world map  based on plotpoints and
 * each country that we have clicked will 
 * play the different video
 */
const MapToPlay = ({ mapData, title }) => {
    const [isShowen, setShown] = useState(false)
    const [videoUrl, setUrl] = useState("")
    const videoRef = useRef("")
    const forceUpdate = useForceUpdate()

    const clickAction = ({ countryName }) => {
        const dataList = mapData.filter((el) => el.name === countryName)
        const currObj = { ...dataList[0] }
        setShown(true)
        setUrl(currObj.video)
        console.log(videoUrl)
        if (videoRef.current) {
          videoRef.current.load()
        }
        forceUpdate()
      };
    return (
        <div className='map-play-container'>
            {mapData ? <WorldMap
                title={title}
                value-suffix="points"
                size="lg"
                data={mapData}
                onClickFunction={clickAction}
            /> : <p>Component Data improper</p>}
            {isShowen ? (
                <div className="video-container">
                <video ref={videoRef} width="600" autoPlay controls>
                    <source src={pathCreater(1) + videoUrl} type="video/mp4" />
                </video>
                </div>
            ) : (
                ""
            )}
    </div>
    )
}

MapToPlay.propTypes = {
    /** Mapdata contains the array of country and video urls. */
    mapData: PropTypes.array.isRequired,
    /** Heading title text of the component */
    title: PropTypes.string
}

export default MapToPlay
