import React, { useEffect, useState, useCallback } from 'react';
import './roadrunner.scss';
// import leaf from "../../../assets/img/leaf.png";
// import bike from "../../../assets/img/bike.png";
import goat from "../../../assets/img/ship.png";
import AppButton from "../../ui/AppButton/AppButton";

const quizQuestions = [
    {
        question: "Which is a better method for shipping large, heavy items over long distances?",
        op:["Sea Freight","Air Freight"],
        // ans:[0]
    },
    {
        question: "Which document is typically required when collecting an international parcel?",
        op:["Sales Receipt","Proof of Identity"],
        // ans:[0]
    },
    {
        question: "Which form is typically used to request permission to export goods from a country?",
        op:["Export License","Certificate of Origin"],
        // ans:[0]
    },
];

const RoadRunner = () => {
    
    const [gameState, setGameState] = useState(0); // 0- start 1-play 2-game over
    const [goatPosition, setGoatPosition] = useState(310); // Starting position of the goat
    const [leafPosition, setLeafPosition] = useState({ top: -40, left: 60 }); // Initial leaf position
    const [bikePosition, setBikePosition] = useState({ top: -50, left: 0 }); // Initial bike position
    const [score, setScore] = useState(0); // Score tracking
    const [gameIntervalId, setGameIntervalId] = useState(null); // Game loop interval ID
   const gameArea=1000
    const gameSpeed = 2; // Game object movement speed
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0); // Track which question we're on
    let currentQuestion = quizQuestions[currentQuestionIndex]; // Get the current question
    const [countdown, setCountdown] = useState(null); // Countdown state


    /**
     * Start the game on component mount.
     * Restart game if it’s not over.
     * Cleanup the game loop on unmount.
     */
    useEffect(() => {
        if(countdown) return;
        if (gameState === 1) {
            startGame();
        }

        return () => {
            clearInterval(gameIntervalId);
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [gameState, currentQuestionIndex, countdown]);

    /**
     * Handle the keydown event for moving the goat (left or right).
     */
    const handleKeyDown = (event) => {
        setGoatPosition((prevPosition) => {
            if (event.key === 'ArrowLeft' && prevPosition > 0) {
                return prevPosition - 20; // Move left if within bounds
            } else if (event.key === 'ArrowRight' && prevPosition < gameArea) {
                return prevPosition + 20; // Move right if within bounds
            }
            return prevPosition;
        });
    };

    /**
     * Start the game, set initial object positions, and start the game loop.
     */
    const startGame = () => {
        // Ensure no existing game loop is running
        if (gameIntervalId) {
            clearInterval(gameIntervalId);
        }
        setLeafPosition(resetObject(1))
        setBikePosition(resetObject(0)) 

        const intervalId = setInterval(() => {
            setLeafPosition((prevPosition) => moveObject(prevPosition,1)); // Move leaf object
            setBikePosition((prevPosition) => moveObject(prevPosition,0)); // Move bike object
            checkCollision(); // Check for collisions
        }, 20);

        setGameIntervalId(intervalId);
        document.addEventListener('keydown', handleKeyDown); // Listen for key press events
    };

    /**
     * Move the object down the screen and reset if it goes off the screen.
     */
    const moveObject = (position,type) => {
        if (position?.top >= 1000) {
            return resetObject(type); // Reset object position when it goes off-screen
        } else {
            return { ...position, top: position?.top + gameSpeed }; // Continue moving object down
        }
    };

    /**
     * Reset object position to a random location at the top of the screen.
     * Ensure objects do not overlap (i.e., leaf and bike appear too close).
     */

    const resetObject = (type) => {
        let objectPosition;
        const gap = 50;
        const leafRect = document.getElementById('leaf')?.getBoundingClientRect();
        const bikeRect = document.getElementById('bike')?.getBoundingClientRect();
        let randomNumber = Math.floor(Math.random() * 201) - 100;
        do {
            objectPosition = Math.floor(Math.random() * (gameArea - gap));
        } while (
            (type === 1 && Math.abs(objectPosition - bikeRect?.left) < gap) ||
            (type === 0 && Math.abs(objectPosition - leafRect?.left) < gap)
        );
        if(type === 1)randomNumber=Math.floor(Math.random() * 301) - 251;
         else randomNumber=Math.floor(Math.random() * 101) - 50;
        return { top: randomNumber, left: objectPosition };
    };
    

    /**
     * Check for collisions between the goat and game objects (leaf and bike).
     * Handle scoring and game over conditions.
     */
    const checkCollision = () => {
        const goatRect = document.getElementById('goat')?.getBoundingClientRect();
        const leafRect = document.getElementById('leaf')?.getBoundingClientRect();
        const bikeRect = document.getElementById('bike')?.getBoundingClientRect();

        if(!(goatRect || leafRect || bikeRect)) return;

        // Check collision with bike
        if (isColliding(goatRect, bikeRect)) {
            clearInterval(gameIntervalId); // Stop the game loop
            setLeafPosition(resetObject(0));
            //setGameState(2);// End the game
            // alert(`Game Over! Your Score: ${score}`); // Display game over message
            handleNextQuestion()
        }
        // Check collision with leaf
        else if (isColliding(goatRect, leafRect)) {
            setScore((prevScore) => prevScore + 1); // Increase score on collision with leaf
            setLeafPosition(resetObject(1)); // Reset leaf after collision
            handleNextQuestion()
        }
        

    };


    const handleNextQuestion = () => {
        setLeafPosition({ top: -50, left: -100 }); 
            setBikePosition({ top: -50, left: -100 });
        // Add a delay before moving to the next question and element resets
        setTimeout(() => {
            // Update question index after delay
            setCurrentQuestionIndex((prevIndex) => {
                const nextIndex = prevIndex + 1;
                if (nextIndex >= quizQuestions.length) {
                    clearInterval(gameIntervalId); // Stop game loop if no more questions
                    setGameState(2); // Set to Game Over if needed
                    return prevIndex; // Keep the current index if game over
                }
                return nextIndex; // Proceed to next question
            });
    
            // Smooth transition by resetting positions after the question change
            setGoatPosition(310); // Reset goat position
            setLeafPosition(resetObject(1)); // Reset leaf position
            setBikePosition(resetObject(0)); // Reset bike position
    
        }, 3000); // Delay of 3 seconds before moving to the next question
    
        // You can also add CSS transitions for smoother visual effect for goat, leaf, and bike positions.
    };
    
        /**
         * Helper function to check if two objects (rectangles) are colliding.
         * Takes into account the left, top, width, and height of both rectangles.
         */
        const isColliding = (rect1, rect2) => {
            return !(
                rect1.left + rect1.width < rect2.left ||  // rect1 is to the left of rect2
                rect1.left > rect2.left + rect2.width ||  // rect1 is to the right of rect2
                rect1.top + rect1.height < rect2.top ||   // rect1 is above rect2
                rect1.top > rect2.top + rect2.height      // rect1 is below rect2
            );
        };


    /**
     * Reset the game state and restart the game.
     */
    const resetGame = () => {
        currentQuestion = quizQuestions[0];
        setGameIntervalId(null)
        setCurrentQuestionIndex(0); 
        setGameState(0);
        setScore(0); // Reset score
        setGoatPosition(310); // Reset goat position
        setLeafPosition(resetObject(1)); // Reset leaf position
        setBikePosition(resetObject(0)); // Reset bike position
        setCurrentQuestionIndex(0); // Reset to the first question
    };

    /**
     * Handle start button click and begin the game.
     */
    const handleStartClick = useCallback(() => {
        setCountdown(3); // Start countdown at 3
        let count = 3;
        setGameState(1); // Start the actual game after countdown ends
        const countdownInterval = setInterval(() => {
            count -= 1;
            setCountdown(count);

            if (count === 0) {
                clearInterval(countdownInterval);
            }
        }, 1000);
    }, []);


    const startBoard=()=>{
        return (
            <div className="game-screen">
                <div className='board'>
                 <h2>Welcome to Target Capture Challenge Game!</h2>
                 <p>Catch the correct items in your bucket. Avoid the wrong items!</p>
                 <AppButton theme="primary1_transparent" onClick={handleStartClick} >Start Game</AppButton>
                </div>
            </div>
        ); 
    }

       /**
     * Main rendering logic based on game state.
     */
       const renderGameContent = useCallback(() => {
        switch (gameState) {
            case 0:
                return startBoard();
            case 1:
                currentQuestion = quizQuestions[currentQuestionIndex]
                return (
                    <>
                        <div className="road">
                            <div className="goat" id="goat" style={{ left: `${goatPosition}px` }}>
                                <img src={goat} alt="Goat" />
                            </div>
                           
                           { !countdown ?  <>
                            <div className="object leaf" id="leaf" style={{ top: `${leafPosition?.top}px`, left: `${leafPosition?.left}px` }}>
                            <p className='option'>{currentQuestion.op[0]}</p>
                            </div>

                            <div className="object bike" id="bike" style={{ top: `${bikePosition?.top}px`, left: `${bikePosition?.left}px` }}>
                            <p className='option'>{currentQuestion.op[1]}</p>
                            </div></> : <div className='game-screen'> <h4 className="countdown">Starting in: {countdown}</h4> </div>}
                        </div>
                         <h2 className="question">{currentQuestion?.question}</h2>
                    </>
                );
            case 2:
                return (
                    <div className="game-screen">
                            <div className='board'>
                        <h2>Game Over!</h2>
                        <p>Your Score: {score}</p>
                        <AppButton theme="primary1_transparent" onClick={resetGame} >Play Again</AppButton>
                        </div>
                    </div>
                );
            default:
                return startBoard();
        }
    },[countdown,leafPosition,bikePosition,goatPosition,currentQuestion]);

    return (
        <div className='game-container'>
        <div className="game-area" style={{width:`${gameArea+150}px`}}>
          {renderGameContent()}
        </div>
     </div>
    );
};

export default RoadRunner;
