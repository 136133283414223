import { useState, useEffect } from "react";
import useForceUpdate from 'use-force-update';
import ReactCardFlip from "react-card-flip";
import '../../../assets/scss/flipcard.scss'
import PropTypes from 'prop-types'
import { getPlaceHolderImg, pathCreater } from "../../../utils/MediaConfig";
import LoopIcon from '@material-ui/icons/Loop';
import ContentTracking from "../../../core/progress/ContentTracking";
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import Tooltip from '@material-ui/core/Tooltip';
import PlayerConstants from '../../../utils/PlayerConstants';
import Instruction from "../../ui/Instruction/Instruction";
import DataHelper from '../../../utils/DataHelper';
import ImageTag from "../../ui/ImageTag";
import useDebounce from "../../../utils/useDebounce"
import t from "../../../translation/useTranslate";
import BuildHelper from "../../../utils/BuildHelper";

/**
 * 
 * @param {*} items 
 * @returns array of items that will have  front image and back content description in object 
 */

/**
 * CourseImageFlip used to flip card between front and back 
 * which contains text and image
*/
const CourseImageFlip = (props) => {
  const contentLayout = document.querySelector('.content')
  const { items, track, handler, instructions, cename } = props
  const UPDATE_STATUS = PlayerConstants.COMPONENT_CONSTANTS.UPDATE_STATUS
  const SAVE_PROGRESS_STATUS = PlayerConstants.COMPONENT_CONSTANTS.SAVE_PROGRESS_STATUS

  const forceUpdate = useForceUpdate()
  const [Flipped, setFlipped] = useState(items);
  const [viewedList, setViewedList] = useState([])
  const [status, setStatus] = useState({ completed: false, text: PlayerConstants.COMPONENT_CONSTANTS.STATUS_INCOMPLETE })
  const [instruction, setInstruct] = useState(instructions)

  const handleClick = (index) => {
    Flipped[index].flipped = !Flipped[index].flipped
    setFlipped(Flipped)
    forceUpdate();

    /** progress update start */
    if (viewedList.indexOf(Flipped[index].id) === -1) {
      viewedList.push(Flipped[index].id);
      forceUpdate();
    }
    setViewedList([...viewedList])
    updateProgress(index)
    /** progress update end */
  }

  // const debouncedAutoFlip =  useDebounce(() => {
  //     setFlipped(prevItems => {
  //       const newItems = [...prevItems];
  //       newItems[0].flipped = !newItems[0].flipped;
  //       return newItems;
  //     });
  // }, 2000);

  useEffect(()=>{
    let data= items;
    if(!track.status && BuildHelper.isLearner() && items?.some(e=>e?.flipped)){
      data= items?.map(e=>{return{...e,flipped:false}});
    }
    setFlipped([...data]);
  },[items])
  
 // kim request 
  // useEffect(() => {
  //   try {
  //     if (viewedList?.length === 0 && !track.status) {
  //       const handleScroll = () => {
  //         const isInViewport = (element) => {
  //           const rect = element.getBoundingClientRect();
  //           return rect.top >= 0 && rect.bottom <= window.innerHeight;
  //         };

  //         const element = document.querySelector(`section[name=${cename}]`);

  //         if (element && isInViewport(element)) {
  //           debouncedAutoFlip()
  //         }
  //       };

  //       contentLayout?.addEventListener('scroll', handleScroll);

  //       return () => {
  //         contentLayout?.removeEventListener('scroll', handleScroll);
  //       };
  //     }else if(Flipped[0].flipped && !track.status){
  //        Flipped[0].flipped = false;
  //     }
  //   } catch (e) { }
  // }, [debouncedAutoFlip, cename, items, viewedList]);

  /**
     * Manage the component update 
     * progress logic in this method
     * 
     * Update the view status when ever the user interacts
     * Update the progess status ONLY ONCE, when status completes
     * 
     */
  const updateProgress = (currentItem) => {

    /**
     * View status, will update while navigating to next topic
     */
    track.state = { ...track.state, c: currentItem }
    /**
     * Progress updated as and when its completed
     */
    if (!track.status) {
      track.status = (viewedList.length === items.length) ? 1 : 0;
      if (track.status) {
        setInstruct({
          ...instruction,
          text:t("CLICK_IMG_INFO"),
          className: 'completed'
        })
        setStatus({ completed: true, text: PlayerConstants.COMPONENT_CONSTANTS.STATUS_COMPLETE })
        handler({ type: SAVE_PROGRESS_STATUS, id: track.id, name: props.cename })
      }
    }
    /**
     * If anything to be intimated always
     * call this
     */
    handler({ type: UPDATE_STATUS, id: track.id, name: props.cename })

  }


  const getImageUrl = (path) => {
    return DataHelper.getResourcePath(0, path);
  }

  return <>
    <Instruction isInstruction={instruction?.enabled} name={props?.cename||""} completed={(track.status === 1 || status.completed)} title={(track.status === 1 || status.completed) ? PlayerConstants.COMPONENT_CONSTANTS.STATUS_COMPLETE : PlayerConstants.COMPONENT_CONSTANTS.STATUS_INCOMPLETE} classText={`${(track.status === 1 || status.completed) && PlayerConstants.COMPONENT_CONSTANTS.COMPLETED_CLASS}`} text={instruction?.text} />
    <div className='flipcard-main-container'>
      <div className='row'>
        {Flipped && Flipped?.length > 0 && Flipped?.map((el, index) => {
          return <div className="col-sm-12 col-md-4 col-lg-4" key={el.id}>
            <ReactCardFlip
              isFlipped={el.flipped}
              className='cardFlip'
            >
              <div className="front" >
                <div className='front-card-container' onClick={(e) => handleClick(index)}>
                  <div className='flip-image'>
                    {/* <img className='flip-img-tag' style={{objectFit: el?.imageFit || 'contain'}} src={getImageUrl(el?.frontImage)} alt="front" onError={(e)=>{e.target.onerror = null; e.target.src=getPlaceHolderImg(200)} } /> */}
                    <ImageTag
                      img={el?.frontImage}
                      bgColor={el?.color || ""}
                      position={String(el?.imageFit).includes("/") ? el?.imageFit : `center  / ${el?.imageFit || "contain"} no-repeat`}
                      maxHeight={250}
                      minHeight={250}
                    />
                    <button className='flip-next' data-count={index}><LoopIcon /></button>
                  </div>
                </div>
              </div>
              <div className="back" >
                <div className='backside-flip-container' onClick={(e) => handleClick(index)}>
                  <div className='flip-back-text'>
                    <div className='text'>
                      <div className="text-content" dangerouslySetInnerHTML={{ __html: el.description }}></div>
                    </div>
                    <button className='flip-next' data-count={index}><LoopIcon /></button>
                  </div>
                </div>
              </div>

            </ReactCardFlip>
          </div>
        })}
      </div>
    </div>
  </>
}

CourseImageFlip.defaultProps = {
  instructions: {
    text: t("CLICK_IMG_INFO"),
    enabled: true
  }
}

CourseImageFlip.propTypes = {
  /** Items having array of front card image and back text content */
  items: PropTypes.array,
  /** Tracking the component progress */
  track: PropTypes.instanceOf(ContentTracking),
  /** Func description for tracking*/
  handler: PropTypes.func
}

export default CourseImageFlip