import React from 'react'
import PropTypes from 'prop-types'
import './searchlist.scss'
import NotesIcon from '@material-ui/icons/Notes';
import { useDispatch, useSelector } from 'react-redux';
import * as courseInfoAction from '../../../redux/actions/courseInfoAction' 

/**
 * 
 * @param {*} query 
 * @returns search query made by the user
 */

/**
 * 
 * @param {*} highlight 
 * @returns function that renders the highlight word
 */

/**
 * 
 * @param {*} flatten 
 * @returns flatten object which will used for easy iteration of course topic
 */

/**
 * 
 * @param {*} courses 
 * @returns array of objects which has each course topic and topiccontent mapped together.
 */

/**
 * SearchList will render the list of topics that we have searched through keyword
*/
const SearchList = ({ query , courses, flatten, highlight}) => {
    const dispatch = useDispatch()
  
    let courseList = []
 

    const filterList = () => {
        if(query.trim() === '' || query === undefined) {
            return courseList = []
        } else {
          const filtered = courses.filter(data => {
            return data.title.toLowerCase().includes(query.trim().toLowerCase()) || data.content.toLowerCase().includes(query.trim().toLowerCase())
          })
          courseList = filtered
        }
    }

    filterList()

    const selectTopic = (topic) => {
        dispatch(courseInfoAction.selectedTopic(flatten[topic]))
        dispatch(courseInfoAction.selectedSection(flatten[topic]));
        setTimeout(() => {
            highlight(query)
        }, 200)
    }
  
    return (
        <div className={`search-list-container ${query !== '' ? 'search-list' : 'no-query'}`}>
            <ul className='search-ul-list'>
                {courseList.length > 0 ? courseList.map((course, index) => <li className='search-content' key={index} onClick={() => selectTopic(course.id)}><span><NotesIcon /></span>{course.title}</li>) : <p className={`result-not-found ${query === '' ? 'not-show': 'show'}`}>No Result Found</p>}  
            </ul>
        </div>  
    )
}

SearchList.propTypes = {
    /**
     * these are the proptypes object which we are using as props data for this component
    */

    /** Search query for the component */
    query: PropTypes.string,
    /** Courses array data for the displaying list */
    courses: PropTypes.array,
    /** Flatten object which will used for easy iteration of course topic */
    flatten: PropTypes.object,
    /** function that renders the highlight word */
    highlight: PropTypes.func
}

export default SearchList
