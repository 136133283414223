import React, { useState, useEffect } from 'react';
import './ImageMapper.scss';
import 'react-image-crop/dist/ReactCrop.css';
import { ImageMap } from "@qiuz/react-image-map";
import ReactCrop from 'react-image-crop';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from "@material-ui/core/styles";
import ContentTracking from '../../../core/progress/ContentTracking';
import PlayerConstants from '../../../utils/PlayerConstants';
import Instruction from '../../ui/Instruction/Instruction';
import ComponentConstants from '../../../utils/ComponentConstants';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@mui/material/IconButton';
import CircleIcon from '@mui/icons-material/Circle';
import SquareIcon from '@mui/icons-material/Square';
import TransformIcon from '@mui/icons-material/Transform';
import CustomSweetAlert from '../../ui/CustomSweetAlert/CustomSweetAlert'
import BuildHelper from '../../../utils/BuildHelper';

const UPDATE_STATUS = PlayerConstants.COMPONENT_CONSTANTS.UPDATE_STATUS
const SAVE_PROGRESS_STATUS = PlayerConstants.COMPONENT_CONSTANTS.SAVE_PROGRESS_STATUS
const contentLayout = document.querySelector('.content')


/**
 * 
 * @param {*} img 
 * @returns the source url of the image
 */


/**
 * 
 * @param {*} areas 
 * @returns content area for the ImageMapper
 */


/**
 * 
 * @param {*} shape 
 * @returns content shape for the ImageMapper
 */

/**
 * 
 * @param {*} text_color 
 * @returns content TextColor for the TextContent
 */

/**
 * 
 * @param {*} background_color 
 * @returns content BackgroundColor for the TextContent
 */

/**
 * 
 * @param {*} crop_area 
 * @returns content Crop Area for the imageCrop
 */


/**
 * 
 * @param {*} topic_id 
 * @returns content TopicId for the ImageMapper
 */

/**
 * 
 * @param {*} edit_idx 
 * @returns content edit_idx for the ImageMapper
 */


/**
 * ImageMapper component will be able to mark the points and can display some message over to it
*/
const ImageMapper = ({ img, areas, crop_area, shape, background_color, text_color, edit_idx, instructions, track, handler, topic_id, cename }) => {
    const [data, setData] = useState({ img, areas, shape, crop_area, background_color, text_color, edit_idx })
    const [crop, setCrop] = useState(null);
    const [mapAreas, setMapAreas] = useState(areas);
    const [editIdx, setEditIdx] = useState(null);
    const [viewedList, setViewedList] = useState([]);
    const [clickIdx, setClickIdx] = useState(-1);
    const [editMode, setEditMode] = useState(false);
    const [isDelete, setIsDelete] = useState(false);

    const [instruction, setInstruct] = useState(instructions);
    const [status, setStatus] = useState({ completed: false, text: PlayerConstants.COMPONENT_CONSTANTS.STATUS_INCOMPLETE })
    const getMaxPanel = Object.values(PlayerConstants.COMPONENTS_LIST).filter((el) => el.name === cename)
    let max_count = 0
    max_count = getMaxPanel[0].max_count;



    /**
     * If  scroll the clickmessage will be close
    */
    useEffect(() => {
        contentLayout.addEventListener("scroll", clickAwaylistener);
        return () => {
            contentLayout.removeEventListener("scroll", clickAwaylistener);
        };
    });


    /** If  mark the points on image changes will tracking */
    const onCropChange = (pixelsCrop, percentCrop) => {
        setCrop(percentCrop);
        let cropCheck = (percentCrop?.width + percentCrop?.height) >= 8;
        if (cropCheck && editIdx !== null) {
            editCropChange("edit");
        }
        setStateItems(clickIdx, false);
    };


    /** If drag is complete props status will update */
    const onCropComplete = (pixelsCrop, percentCrop) => {
        setCrop(percentCrop);
        let cropCheck = (percentCrop?.width + percentCrop?.height) >= 8;
        if (cropCheck && max_count > mapAreas?.length && editIdx === null) {
            editCropChange('OPEN');
            setCrop(null);
        }

    }

    const editCropChange = async (mode) => {
        let items = [...mapAreas];
        const { x, y, width, height } = crop;
        let style = {
            background: items[editIdx || 0]?.style?.background || "#13984B",
            borderRadius: (mode === "edit") ? items[editIdx]?.style?.borderRadius : shape === "circle" ? "50%" : "",
            opacity: items[editIdx || 0]?.style?.opacity || 1
        }
        let newArea = {
            width: `${width}%`,
            height: `${height}%`,
            left: `${x}%`,
            top: `${y}%`,
            style: { ...style },
            message: items[editIdx]?.message || "message",
            title: items[editIdx]?.title || `Label ${mapAreas.length + 1}`,
            isOpen: false,
            shape: style?.borderRadius === "50%" ? "circle" : "rect"
        };
        if (mode === "edit") items[editIdx] = await { ...items[editIdx], ...newArea };
        else items = [...items, newArea]
        setMapAreas(items);
        let props = { ...data, areas: items, shape: newArea?.shape, crop_area: null, img: img };
        updateProps(props, 'UPDATE');
        if (mode !== "edit")  updateProps(props, 'SAVE_PROP');
        setEditMode(true);
    }


    const editCropUpdate = () => {
        let props = { ...data, areas: mapAreas, crop_area: null, edit_idx: null, img: img };
        updateProps(props, 'UPDATE');
        setEditIdx(null);
        setCrop(null);
    }

    const editHighlight = (Index) => {
        try {
            const { left, top, width, height } = mapAreas[Index];
            let editArea = {
                unit: '%',
                width: Number(width?.slice(0, -1)),
                height: Number(height?.slice(0, -1)),
                x: Number(left?.slice(0, -1)),
                y: Number(top?.slice(0, -1)),
            };
            setCrop(editArea);
        } catch (e) { }
    }

    const handleEditMode = (idx) => {
        setEditIdx(idx);
        editHighlight(idx);
    }

    const handleShape = (index) => {
        const updated = [...mapAreas];
        updated[index] = { ...updated[index], style: { ...updated[index].style, borderRadius: updated[index]?.style?.borderRadius === "50%" ? "" : "50%" } };
        setMapAreas(updated);
        let props = { ...data, img: img, areas: updated, shape: updated[index]?.style?.borderRadius === "50%" ? "circle" : "rect", crop_area: null };
        updateProps(props, 'UPDATE');
        setEditIdx(null);
        setCrop(null)
    }
    /* ImageMapperPanel changes tracking */
    useEffect(() => {
        setMapAreas(areas);
    }, [areas, img, shape]);



    const setStateItems = (index, flag) => {
        setMapAreas(areas);
        let items;
        items = [...mapAreas];
        if (flag) {
            items = mapAreas?.map((item, idx) => idx !== index ? { ...item, isOpen: false } : { ...item, isOpen: flag });
        } else if (items[index]) {
            items[index] = { ...items[index], isOpen: flag };
        }
        setMapAreas(items);
    }


    const tooltipClose = (index) => {
        setStateItems(index, false)
    };

    const tooltipOpen = (index) => {
        if (editIdx >= 0 && editIdx !== null) {
            editCropUpdate();
        } else {
            if (mapAreas[index]?.isOpen) {
                setStateItems(index, false)
            } else {
                setStateItems(index, true);
                setClickIdx(index);
                /**progress tracking start*/

                if (viewedList?.indexOf(index) === -1) {
                    viewedList?.push(index);
                    updateProgress(index)
                }
                setViewedList(viewedList);
            }

            /** progress update end */
        }
    }



    /**
     * Manage the component update 
     * 
     * Update the props status when ever the user changes
     * 
    */
    const updateProps = (props, type) => {
        if (Number(window.ce.ceuser?.role_id ?? 4) !== 4) {
            let activeAreas = [...props?.areas || []];
            activeAreas?.map(item => ({ ...item, isOpen: false }));
            let updatedProps = { img, areas, shape, crop_area, background_color, text_color, edit_idx };
            updatedProps = { ...updatedProps, ...props, topic_id: topic_id, areas: activeAreas };
            setData(updatedProps);
            switch (type) {
                case 'UPDATE':
                    /*If cropper-updating call to update props*/
                    handler({ type: ComponentConstants?.WYSIWYG, props: updatedProps, topic_id: topic_id });
                    break;
                case 'OPEN': //open the edit panel
                    handler({ type: ComponentConstants?.WYSIWYG, props: updatedProps, topic_id: topic_id });
                    handler({ type: ComponentConstants?.SAVE_COMP_PROPS, data: updatedProps })
                    break;
                case 'SAVE_PROP':
                    handler({ type: ComponentConstants?.SAVE_COMP_PROGRESS_PROPS, props: updatedProps, topic_id: topic_id });
                    break;
            }
        }
    }


    /**
     * Manage the component update 
     * progress logic in this method
     * 
     * Update the view status when ever the user interacts
     * Update the progess status ONLY ONCE, when status completes
     * 
     * c - click count
     * 
     * 
    */
    const updateProgress = (currentItem) => {
        /**
         * View status, will update while navigating to next topic
         */
        track.state = { ...track.state, c: currentItem }
        /**
         * Progress updated as and when its completed
         */
        if (!track.status) {
            track.status = (viewedList?.length === areas?.length) ? 1 : 0;
            if (track.status) {
                setInstruct({
                    ...instruction,
                    text: `You have! completed ${cename}, you can proceed to next section`,
                    className: 'completed'
                })
                setStatus({ completed: true, text: PlayerConstants.COMPONENT_CONSTANTS.STATUS_COMPLETE })
                handler({ type: SAVE_PROGRESS_STATUS, id: track.id, name: cename })
            }
        }
        /**
         * If anything to be intimated always
         * call this
         */
        handler({ type: UPDATE_STATUS, id: track?.id, name: cename })

    }

    /**
      * highlight area Click handling
      * 
    */
    const onAreaClick = (area, index) => {
        tooltipOpen(index)
    }
    const clickAwaylistener = () => {
        let openToolTip = mapAreas.findIndex((e) => e?.isOpen)
        if (clickIdx >= 0 || openToolTip >= 0) {
            /*if close tooltip call this */
            setStateItems(clickIdx, false);
            mapAreas[openToolTip || 0] = { ...mapAreas[openToolTip || 0], isOpen: false }
            setMapAreas(mapAreas);
        }

        if (editIdx >= 0 && editMode) {
            // Delay this action by seconds
            editCropUpdate()
            setEditMode(false)
        }
    }


    const ImageMapComponent = React.useMemo(
        () => (
            <ImageMap
                className="usage-map"
                src={img}
                map={mapAreas}
                onMapClick={onAreaClick}
                onClick={clickAwaylistener}
            />
        ),
        [img, areas, mapAreas, shape]
    );


    const HtmlTooltip = withStyles((theme) => ({
        tooltip: {
            backgroundColor: `${background_color} !important`,
            color: `${text_color} !important`,
            fontSize: theme.typography.pxToRem(20),
            border: '1px solid #dadde9',
        },
        arrow: {
            color: `${background_color} !important`,
        },
    }))(Tooltip);


    const handleDelete = (index) => {
        if (mapAreas?.length > 1) {
            const removed = mapAreas.filter((e, i) => i !== index);
            setMapAreas(removed);
            let props = { ...data, areas: removed, crop_area: null, img: img };
            updateProps(props, 'UPDATE');
            updateProps(props, 'SAVE_PROP');
            setIsDelete(false);
        }
    }

    const deleteArea = (index) => {
        if (mapAreas?.length > 1) {
            setIsDelete(index);
        }
        setEditIdx(null);
    }

    const handleOpen = (index) => {
        let props = { ...data, areas:mapAreas, edit_idx: index, crop_area: null, img: img };
        updateProps(props, 'SAVE_PROPS');
        updateProps(props, "OPEN");
        setEditIdx(null);
    }



    const getShape = () => {
        if (editIdx || editIdx === 0) {
            return mapAreas[editIdx]?.style?.borderRadius === '50%'
        }
        return shape === 'circle' ? true : false
    }


    const mapPoint = (map, index) => {
        const { top, left, width, height } = map;
        return (<span className="crop-item" style={{ top, left, width, height, ...map?.style, opacity: 1 }}>
            {!BuildHelper.isLearner() ? <div className='icons-holder' style={{ ...(map?.style?.borderRadius === '50%' && { left: '50%' }), width: "86px", height: "25px" }}>
                <IconButton className="action-btns" aria-label="button">
                    <TransformIcon className='edit-shape' onClick={() => handleEditMode(index)} />
                    <span className="shape" onClick={() => handleShape(index)}>{map?.style?.borderRadius !== '50%' ? <CircleIcon onClick={() => handleShape(index)} /> : <SquareIcon />}</span>
                    <DeleteIcon className='delete-icon' onClick={() => deleteArea(index)} />
                    <EditIcon className='edit-icon' onClick={() => handleOpen(index)} />
                </IconButton>
            </div> : <></>}
            <span className="inner-box" onClick={() => tooltipOpen(index)}>
                <span className='crop-item-title' style={{ color: text_color }} dangerouslySetInnerHTML={{ __html: map?.title }} onClick={() => tooltipOpen(index)}></span>
            </span>
        </span>)
    }


    return (
        <>
            <Instruction isInstruction={instruction?.enabled} completed={(track.status === 1 || status.completed)} title={(track.status === 1 || status.completed) ? PlayerConstants.COMPONENT_CONSTANTS.STATUS_COMPLETE : PlayerConstants.COMPONENT_CONSTANTS.STATUS_INCOMPLETE} classText={`${(track.status === 1 || status.completed) && PlayerConstants.COMPONENT_CONSTANTS.COMPLETED_CLASS}`} text={(track.status === 1 || status.completed) ? PlayerConstants.COMPONENT_CONSTANTS.INSTRUCTIONS_PASSED : instruction?.text} />
            <div className="images-map-content">
                <div className="crop-box">
                    <div className="map-box">
                        <div className="map-box-img" >
                            {!BuildHelper.isLearner() ?
                                <ReactCrop circularCrop={getShape()} crop={crop} onComplete={onCropComplete} onChange={onCropChange} >
                                    {ImageMapComponent}
                                </ReactCrop> : ImageMapComponent}
                            {img && areas?.length > 0 &&
                                mapAreas?.map((map, index) => {

                                    return (
                                        <HtmlTooltip
                                            interactive={true}
                                            className='image-map-tooltip'
                                            key={index}
                                            PopperProps={{
                                                disablePortal: true,
                                            }}
                                            onClose={() => tooltipClose(index)}
                                            open={map?.isOpen||false}
                                            disableFocusListener
                                            disableHoverListener
                                            disableTouchListener
                                            arrow
                                            placement="top"
                                            title={
                                                <>
                                                    <div className='tooltip-snippet' dangerouslySetInnerHTML={{ __html: map?.message }}></div>
                                                </>
                                            }
                                        >
                                            {mapPoint(map, index)}
                                        </HtmlTooltip>
                                    );
                                })}


                        </div>
                    </div>
                </div>
                <div className='warning-popup danger-popup'>
                 <CustomSweetAlert 
                   warning
                  showCancel
                  show={(isDelete || isDelete === 0) ? true : false}
                  confirmBtnText="Delete"
                  confirmBtnBsStyle="danger"
                  title={<p className="sweet-title">Are you sure you want to delete this area ?</p>}
                  onConfirm={() => handleDelete(isDelete)}
                  onCancel={() => setIsDelete(false)}
                 />
                </div>
            </div>
        </>
    )
}



ImageMapper.defaultProps = {
    instructions: {
        text: 'Click on each hotspot on image for more information',
        enabled: false
    }
}

ImageMapper.propTypes = {
    /** Src contains source url of the image */
    img: PropTypes.string.isRequired,
    /** Areas content area for the  ImageMapper*/
    areas: PropTypes.array.isRequired,
    /** Shape content Shape for the ImgeCrop */
    shape: PropTypes.string.isRequired,
    /** Tracking the component progress */
    track: PropTypes.instanceOf(ContentTracking),
    /** Func description for tracking*/
    handler: PropTypes.func,
    /** crop_area content has tracking ImgeCrop*/
    crop_area: PropTypes?.object,
    /** background_color content has tracking ImageMapper*/
    background_color: PropTypes?.string,
    /** text_color content has tracking ImageMapper*/
    text_color: PropTypes?.string,
    /** TopicId content has tracking ImageMapper*/
    topic_id: PropTypes?.string,
    /** edit_idx content has tracking ImageMapper*/
    edit_idx: PropTypes?.number,
}


export default ImageMapper