import React, { useState, useCallback, useEffect } from 'react'
import Video from '../Video/Video'
import './videohotspot.scss';
import ComponentConstants from '../../../utils/ComponentConstants';
import Close from '@material-ui/icons/Close';
import AppButton from "../../ui/AppButton/AppButton"
import MuiIcons from '../../ui/MuiIcons/MuiIcons';
import BuildHelper from '../../../utils/BuildHelper';
import Instruction from '../../ui/Instruction/Instruction';
import PlayerConstants from '../../../utils/PlayerConstants';

/**
 * VideoHotspot component allows learners to interact with video content that contains hotspots (cue points)
 * where additional information is displayed, and progress is tracked.
 * 
 * @param {string} src - Video source URL
 * @param {string} cename - Component name for tracking
 * @param {number} topic_id - ID of the current topic
 * @param {Array} items - Array of hotspot items (cue points) to display
 * @param {function} handler - Function to handle status updates and interactions
 * @param {Object} instructions - Instructional content
 * @param {string} poster - Poster image for the video
 * @param {boolean} forward_rewind - Flag to enable/disable forward/rewind controls
 * @param {Object} track - Tracking information for the user's progress
 * @param {string} intro - Introductory content for the video
 * @param {string} description - Video description content
 * @param {string} summary - Summary content displayed at the end
 * @param {string} warning - Warning message displayed when necessary
 */
const VideoHotspot = ({ src, cename, topic_id, items, handler,instructions, poster, forward_rewind,track, intro, description, summary,warning }) => {
  
    /**
   * State management for the video player, hotspots, and user interaction tracking.
   * @typedef {Object} Action
   * @property {number|null} cIdx - Current hotspot index
   * @property {number} cDuration - Current video duration
   * @property {Object|null} player - Video player instance
   * @property {Array} hotspot - Hotspot data (cue points) for video interaction
   * @property {Array} viewedItems - Items the user has viewed
   * @property {boolean} hasStart - Flag indicating if the video has started
   * @property {Object|null} content - Current content (intro, summary, etc.)
   */
  const [action, setAction] = useState({
    cIdx:null,
    cDuration:0,
    player:null,
    hotspot:JSON.parse(JSON.stringify(items)),
    viewedItems:[],
    hasStart:false,
    content:null,
  });

  const { hotspot, currentIDX, currentContent, viewedItems, player, currentDuration } = action;

  const [status, setStatus] = useState({
    completed: false,
    text: PlayerConstants.COMPONENT_CONSTANTS.STATUS_INCOMPLETE
  });
  const [instruction, setInstruct] = useState(instructions);
  const UPDATE_STATUS = PlayerConstants.COMPONENT_CONSTANTS.UPDATE_STATUS
  const SAVE_PROGRESS_STATUS = PlayerConstants.COMPONENT_CONSTANTS.SAVE_PROGRESS_STATUS

   /**
   * useEffect hook that initializes or resets the hotspot data and player state
   * whenever the component props (items, intro, description, summary, warning) change.
   */
  useEffect(() => {
    if(!BuildHelper.isLearner()){
      setAction((prevState) => ({
        ...prevState,
        hotspot:JSON.parse(JSON.stringify([...items])),
        currentContent:{ info: intro },
        cIdx:null,
        viewedItems:[],
      }))
      if(action?.player) action?.player?.currentTime(0);
    }
  }, [items,intro, description, summary,warning]);

   /**
     * Manage the component update 
     * progress logic in this method
     * 
     * Update the view status when ever the user interacts
     * Update the progess status ONLY ONCE, when status completes
     * 
     */
   const updateProgress = () => {

    /**
     * View status, will update while navigating to next topic
     */
    track.state = { ...track.state }
    /**
     * Progress updated as and when its completed
     */

    if (!track.status) {

      track.status = 1
      setStatus({ completed: true, text: PlayerConstants.COMPONENT_CONSTANTS.STATUS_COMPLETE })
      if (track.status) {
        handler({ type: SAVE_PROGRESS_STATUS, id: track.id, name: cename })
      }
    }
    /**
     * If anything to be intimated always
     * call this
     */
    handler({ type: UPDATE_STATUS, id: track.id, name:cename })

  }

  // Handle double-click event
  const handleDoubleClick = useCallback(() => {
    if (!player) return;
    player.isFullscreen() ? player.exitFullscreen() : player.requestFullscreen();
  }, [player]);

  // set up the double-click listener after the player is initialized
  useEffect(() => {
    if (player) {
      const videoElement = player.el().querySelector('video');
      if (videoElement) {
        videoElement.addEventListener('dblclick', handleDoubleClick);

        // cleanup listener on component unmount or player change
        return () => {
          videoElement.removeEventListener('dblclick', handleDoubleClick);
        };
      }
    }
  }, [player]);

  /**
   * handleClick function checks for cue points in the current video duration and updates
   * the viewed items and hotspots accordingly. Pauses the video at the hotspot.
   */
  const handleClick = () => {
    const index = hotspot?.findIndex(({ point, show }) =>
      point && !show && currentDuration?.t >= point[0] && currentDuration?.t <= point[1]
    );
    const videoElement = player.el().querySelector('video');

    if (index > -1) {
      videoElement.pause();  
      const updatedCuepoints = hotspot.map((item, i) => (i === index ? { ...item, show: true } : item));
      updatedCuepoints[index].show = true;
      setAction((prevState) => ({
        ...prevState,
        hotspot: updatedCuepoints,
        currentContent: updatedCuepoints[index],
        viewedItems: [...prevState.viewedItems, updatedCuepoints[index]],
        currentIDX: prevState?.viewedItems?.length,
      }));
      
      if (player?.isFullscreen()) {
        player.exitFullscreen();
      }
    }
  };


  /**
   * handleStepClick function allows users to manually navigate through hotspots
   * by clicking on the step indicators (e.g., 'Start', 'Step 1', etc.)
   */
  const handleStepClick = (content, index) => {
    setAction((prevState) => ({
      ...prevState,
      currentContent: content,
      currentIDX: index,
    }));
    const videoElement = player.el().querySelector('video');
    videoElement.pause();
  if(player) player.currentTime(Number(content?.point[0]));

  }

  /**
   * handleListening function disables pointer events during cue points to avoid
   * interaction when the overlay is active.
   */
  const handleListening = useCallback(
    (payload) => {
      const index = hotspot.findIndex(({ point, show }) =>{
       return point && !show && payload.t >= point[0] && payload.t <= point[1]
    });
      const videoElement = payload.p.el().querySelector('video');
      videoElement.style.pointerEvents = index > -1 ? 'none' : 'auto';
    },
    [hotspot]
  );

  /**
   * handleAction function manages different video event types such as video start, progress update,
   * cue point handling, and saving component properties.
   */
  const handleAction = useCallback((vData, cue) => {
    if (!vData) return; // Safeguard for undefined or null vData

    const handlerData = vData?.data;

    switch (vData?.type) {
      case '1':
        setAction((prevState) => ({
          ...prevState,
          hasStart:true,
          player: handlerData,
          currentContent: { info: intro },
        }));
        break;
      case '2':
        setAction((prevState) => {
          const newViewedItems = [...prevState?.viewedItems];
          const isCompleted = newViewedItems.length === prevState?.hotspot?.length;

          if (isCompleted) {
            updateProgress();
            if(newViewedItems.length>0)newViewedItems[newViewedItems?.length-1]={...newViewedItems[newViewedItems?.length-1],isEnd: true}
            else newViewedItems=[{ isEnd: true }]
            return {
              ...prevState,
              currentContent: { info: summary, isEnd: true },
              currentIDX: -1,
              viewedItems: [...newViewedItems],
            };
          } else {
            return {
              ...prevState,
              currentContent: { info: warning, isEnd: true, hasTry: true },
            };
          }
        });
        break;

      case 'SAVE_COMP_PROPS':
        handler({
          type: ComponentConstants?.SAVE_COMP_PROPS,
          data: {
            cename,
            topic_id,
            cduration: handlerData?.cduration,
            isEdit: handlerData?.isEdit,
          },
        });
        break;
      case '3': //timeupdat
      setAction((prevState) => ({
        ...prevState,
        currentDuration: handlerData,
        player: prevState?.player ? prevState?.player: handlerData?.p
      }));
        handleListening(handlerData)
        break;
      default:
        return; 
    }
  }, [player, intro, summary, items?.length, hotspot?.length, viewedItems?.length ])


  const handleEndClick=()=>{
    if(!viewedItems[Number(viewedItems?.length-1)]?.isEnd)return;
    if (viewedItems?.length === hotspot?.length) {
      setAction((prevState) => ({
        ...prevState,
        currentIDX: null,
        currentContent: { info: summary, isEnd: true },
        viewedItems: prevState?.viewedItems?.map((item, index) =>
          index === prevState?.viewedItems.length - 1 ? { ...item, isEnd: true } : item
        ),
      }));
    }else {
      setAction((prevState) => ({
        ...prevState,
        currentContent: { info: warning, isEnd: true, hasTry: true },
      }));
    }
  }


  const handleStart = () => {
    setAction((prevState) => ({
      ...prevState,
      currentContent: null,
      currentIDX: null,
    }));
    if (!currentContent?.isEnd && player) {
      player.play();
    }
  }

  const handleShow=()=>{
    const updatedCuepoints = hotspot.map((item, index) =>
      index === hotspot.length - 1 ? { ...item, show: true, isEnd: true } : { ...item, show: true }
    );

    setAction((prevState) => ({
      ...prevState,
      viewedItems: updatedCuepoints,
    }));
    updateProgress();
  }

  const overLay = useCallback(() => {
    if (!currentContent) return null;
    let content = currentContent;
    const hasStart = content?.info
    return <>
      {/* <div className='overlay-container-vid-hot'> */}
        <div className='overlay-render-vid-hot'>
          <div className="add-on-overlay-vid-hot" style={{ backgroundColor: "#fff " }}>
            <div className="head-item">
              {content?.title && <p className='overlay-title p-3 m-0'>{content?.title} </p>}
              <Close className="close-btn" onClick={() => handleStart()} />
            </div>

            {content?.desc && <div className="content-item p-3" dangerouslySetInnerHTML={{ __html: content?.desc }} />}
            {hasStart && <>
              <div className='d-flex info-cnt'> {!content?.isEnd && <MuiIcons size='medium' iconName='tuch' />} <div className="py-3" dangerouslySetInnerHTML={{ __html: content?.info }} /></div>
            </>
            }
            <div className='btn-container'> {content?.hasTry && <AppButton
              size="small"
              className="py-2"
              onClick={handleShow}
            >Show All</AppButton>} <AppButton
            size="small"
            onClick={handleStart}
          >{content?.isEnd ? 'Close' : 'Continue'}</AppButton></div>
          </div></div>
      {/* </div> */}
    </>
  },[currentContent, summary, intro, items])



  const videoLRN = React.useMemo(() => {
    return (
      <div
        onClick={handleClick}
        className={`${currentContent ? 'popup-hide' : ''} video-area`}
      >
        <Video
          src={src}
          handleHotspot={(prop)=>handleAction(prop)}
          poster={poster}
          hasCue
          forward_rewind={forward_rewind}
          hasHotspot
        />
      </div>
    );
  }, [handleClick, currentContent, src, poster, forward_rewind,hotspot, items]);
  

  return (<>
    <Instruction isInstruction={instruction?.enabled} completed={(track.status === 1 || status.completed)} title={(track.status === 1 || status.completed) ? PlayerConstants.COMPONENT_CONSTANTS.STATUS_COMPLETE : PlayerConstants.COMPONENT_CONSTANTS.STATUS_INCOMPLETE} classText={`${(track.status === 1 || status.completed) && PlayerConstants.COMPONENT_CONSTANTS.COMPLETED_CLASS}`} text={(track.status === 1 || status.completed) ? PlayerConstants.COMPONENT_CONSTANTS.INSTRUCTIONS_PASSED : instruction?.text} />
    <div className="videxplo-container">
    {videoLRN}

      {action?.hasStart && (<div className="stepper">

        <div
          className={`step ${viewedItems?.length > 0 ? 'active-bar' : 'active-action'} visible-action`}
          onClick={() => handleStepClick({ info: intro,point:[0] })}
        >
          <div className="step-circle">Start</div>
        </div>

        {viewedItems?.map((e, index) => {
          const isActive = currentIDX === index;
          // const isCompleted = currentIDX > index;
          return (
            <div
              key={index}
              className={`
            step 
            ${e?.show ? 'visible-action' : ''} 
            ${isActive ? 'active-action' : ''} 
            ${viewedItems?.[viewedItems.length - 1]?.isEnd ? 'visible-action active-bar' : ''} 
            ${(currentIDX === index && currentContent?.desc) ? 'active-action' : ''}
          `}
              onClick={() => handleStepClick(e, index)}
            >
              <div className="step-circle">{index + 1}</div>
            </div>
          );
        })}

        {(
          <div
            className={`step ${viewedItems[Number(viewedItems?.length - 1 || 0)]?.isEnd ? 'visible-action active-bar' : ''} end-step`}
            onClick={() => handleEndClick()}
          >
            <div className="step-circle">End</div>
          </div>
        )}
      </div>)}
      {description && <div className="content-item p-3" dangerouslySetInnerHTML={{ __html: description }} />}
      {currentContent && overLay()}
    </div>
    </>
  )
}

export default VideoHotspot