import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import './topicpanel.scss'
import { useDispatch, useSelector } from 'react-redux'
import TextField from '@material-ui/core/TextField';
import * as courseEditAction from '../../../redux/actions/courseEditAction'
import * as courseInfoAction from '../../../redux/actions/courseInfoAction'
import { Button } from '@material-ui/core';

const TopicPanel = () => {

    const dispatch = useDispatch();
    const courseInfo = useSelector(state => state.courseInfo);
    const [title, setTitle] = useState(courseInfo?.topic?.title);
    const [duration, setDuration] = useState(courseInfo?.topic?.duration||"");

    const handleUserInput = (e) => {
        if (e.target.name === 'topic') {
            setTitle(e.target.value)
        }
        else {
            setDuration(e.target.value)
        }
    }

    const closeDrawer = () => {
        dispatch(courseEditAction.toggleDrawer({ open: false, drawerWidth: 0 }))
    }

    const resetInput = () => {
        closeDrawer()
    }

    /** topic save here */
    const saveTopic = () => {
        let tpData = {
            id: courseInfo.topic.id,
            topicname: title,
            duration: duration
        }
        dispatch(courseInfoAction.editTopic(tpData))
        closeDrawer()
    }

    /** check  duration is mandatory or not */
    const isDisabled = () => {
        return title === '' || duration === '' ? true : false
    }

    return (
        <div className='topic-edit-panel'>
            <div className='row'>
                <div className='col-lg-12 col-sm-12 col-md-12'>
                    <TextField id="outlined-basic"
                        name='topic'
                        inputProps={{ maxLength: 80 }}
                        label="Topic name"
                        variant="outlined" defaultValue={title}
                        onClick={(e) => e.stopPropagation()}
                        onChange={(e) => handleUserInput(e)} />
                </div>
            </div>
            <div className='row mt10'>
                <div className='col-lg-12 col-sm-12 col-md-12'>
                    <TextField id="outlined-basic"
                        name='duration'
                        type="number"
                        inputProps={{
                            max: 100000, min: 1
                        }}
                        label="Duration"
                        variant="outlined" defaultValue={duration}
                        onClick={(e) => e.stopPropagation()}
                        onChange={(e) => handleUserInput(e)} />
                </div>
            </div>
            <div className='tp-action-info'>
                <Button className='cancel-btn' onClick={() => resetInput()}>Cancel</Button>
                <Button className={isDisabled() ? 'diabled-btn' : 'submit-btn'}
                    disabled={isDisabled()} onClick={() => saveTopic()}>Save </Button>
            </div>
        </div>
    )
}

TopicPanel.propTypes = {

}

export default TopicPanel