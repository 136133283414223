// src/hooks/useToastNotifications.js

import { useToasts } from 'react-toast-notifications';

const useToastNotifications = () => {
  const { addToast } = useToasts();

  // base function to show a toast
  const showToast = (message, options = {}) => {
    if (!message) {
      console.error('showToast: message is required.');
      return;
    }

    // ensure options is an object
    if (typeof options !== 'object' || options === null) {
      console.error('showToast: options should be an object.');
      options = {}; // reset to an empty object if invalid
    }

    addToast(message, {
      appearance: options.appearance || 'info', // default appearance
      autoDismiss: options.autoDismiss !== undefined ? options.autoDismiss : true, // default to auto-dismiss
      ...options, // merge additional options
    });
  };

  // factory function to generate toast functions
  const createToast = (appearance) => (message, options = {}) => {
    if (typeof appearance !== 'string' || !appearance) {
      console.error('createToast: appearance should be a non-empty string.');
      appearance = 'info'; // fallback to default appearance
    }
    
    showToast(message, { ...options, appearance });
  };

  // exported functions for different toast types
  return {
    showToast, // general purpose toast function
    showSuccess: createToast('success'),
    showError: createToast('error'),
    showWarning: createToast('warning'),
    showInfo: createToast('info'),
  };
};

export default useToastNotifications;
