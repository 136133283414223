import React, { useEffect } from 'react'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import './ckeditor.scss'
import PropTypes from 'prop-types'

const CKeditor = ( { data, eventHandler } ) => {

    useEffect( () => {

    }, [] );

    const handleChange = (e, editor) => {
        // let data = editor.getData();
        // eventHandler(data)
        console.log('event for keydown is ', e, editor.getData())
    }

    return (
        <div className='ck-editor-container'>
            
            <CKEditor
                editor={ ClassicEditor }
                /** Add or remove your controls from configuration */
                defaultConfig={ ClassicEditor.defaultConfig = {
                    toolbar: {
                        items: [
                            'heading',
                            '|',
                            'bold',
                            'italic',
                            '|',
                            'bulletedList',
                            'numberedList',
                            '|',
                            'insertTable',
                            '|',
                            'undo',
                            'redo'
                        ]
                    },
                    
                    image: {
                        toolbar: [
                            'imageStyle:full',
                            'imageStyle:side',
                            '|',
                            'imageTextAlternative'
                        ]
                    },
                    table: {
                        contentToolbar: [ 'tableColumn', 'tableRow', 'mergeTableCells' ]
                    },
                    language: 'en'
                }
                }
                data={ data }
                change
                onChange={ ( event, editor ) => {
                    let data = editor.getData();
                    eventHandler(data)                  
                } }
            />
        </div>
    )
}

CKeditor.propTypes = {
    /** data props used to map the content innerhtml to the  server*/
    data: PropTypes.string
}

export default CKeditor
