import * as types from '../reducers/types'
import BuildHelper from '../../utils/BuildHelper';

const initialState = {
    isLoading:false,
    audios: null,
    videos:null,
    images: null,
    gif:null,
    wishlist: [],
    allCeAssets: [],
    archiveCourses: [],
    archiveSearchCrs: [],
    selectedArchiveCrs:[],
    quizQuestions: [],
}

const ceAssetsInfoReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.CE_ASSETS_LIBARARY_RES:
            let { data, rtype, status } = action?.payload || {};
            let {images,videos,audios,gif,allCeAssets}=state;
            if (status === 'success') {
                let sortedAssets = data.sort((a, b) => new Date(b.createdAt || b.updatedAt) - new Date(a.createdAt || a.updatedAt));
                 // convert `sortedAssets` into an object where each asset is keyed by its `id`
                [...(allCeAssets||[]),...sortedAssets]?.forEach(item => {
                    allCeAssets[item?._id] = item; // store asset by its `id`
                });
                            // allCeAssets = [...allCeAssets, ...sortedAssets];
                /**
                 * By default, the initial state uses `null`.
                 * `data` is set to `null` only when making the API call; otherwise, it remains as is.
                 * The API triggers for images, audio, and video are handled in the `UploaderPanel`.
                 * The API trigger for GIFs is handled in the `AssetUploader.js`.
                 * During configuration, we send the crsids data.
                 * During the course time, the payload crsids is empty.
                 * 
                 */
                switch (rtype) {
                    case 1:
                        images = [...(images || []), ...sortedAssets];
                        break;
                    case 2:
                        videos = [...(videos || []), ...sortedAssets];
                        break;
                    case 3:
                        audios = [...(audios || []), ...sortedAssets];
                        break;
                    case 5:
                        gif = [...(gif || []), ...sortedAssets];
                        break;
                    default:
                        break;
                }
            }
            return {
                ...state,
                isLoading:false,
                audios: audios,
                images: images,
                videos: videos,
                gif:gif,
                allCeAssets: allCeAssets
            }
        case types.CE_ASSETS_WISHLISTS_RES:
            return {
                ...state,
                isLoading:false,
                wishlist: action?.payload?.data
            }
        case types.CE_ASSETS_WISHLISTS_REQ:
        case types.CE_ASSETS_LIBARARY_REQ:
        case types.GET_ARCHIVE_SEARCH_REQ:
            return {
                ...state,
                isLoading:true
            }
        case types.QUIZ_QUESTIONS_RES:
            const quiz=action?.payload;
            let updatedQuiz=state?.quizQuestions;
            if(updatedQuiz.findIndex((e)=>e?._id===quiz?._id) !== -1){
                updatedQuiz= updatedQuiz.map((el)=>{
                    return el?._id === quiz?._id ? {...quiz} : el;
                });
            }else updatedQuiz.push(quiz);
            return{
                ...state,
                quizQuestions:updatedQuiz
            }
            
        case types.CE_ASSETS_LINKED_RES:
            const updateItem = action?.payload;
            let type = action?.payload?.rtype
            let updateImages = state?.images, updateVideos = state?.videos, upadateAudios = state?.audios;
            let index
            if (type === 1) {
                index = updateImages?.findIndex((item) => item?._id === updateItem?.id);
                updateImages[index] = updateItem;
            } else if (type === 2) {
                index = updateVideos?.findIndex((item) => item?._id === updateItem?.id);
                updateVideos[index] = updateItem;
            } else if (type === 3) {
                index = upadateAudios?.findIndex((item) => item?._id === updateItem?.id);
                upadateAudios[index] = updateItem;
            }
            return {
                ...state,
                isLoading:false,
                audios: upadateAudios,
                images: updateImages,
                videos: updateVideos
            }

        case types.GET_ARCHIVE_COURSES_RES:
            const contentRowData = action?.payload;
            const flatten = [];
            contentRowData?.forEach((item) => {
                flatten[item?.info?.config?.cid] = {
                    ...item,
                    id: item?.info?.config?.cid,
                    label: item?.coursename || 'next'
                };
            });
            return {
                ...state,
                isLoading:false,
                archiveCourses: flatten
            }


        case types.GET_QUIZ_QUESTIONS_RESPONSE:
            // const key= BuildHelper.isConfig() ? "cnf_q" : "crs_q";
            const quizzes=action?.payload?.data || [];
            return {
                ...state,
                isLoading:false,
                quizQuestions: quizzes
            }
        case types.GET_QUIZ_ADD_QUESTIONS_RESPONSE:
                return {
                    ...state,
                    quizQuestions: action?.payload?.data
                }
        case types.GET_QUIZ_UPDATE_QUESTIONS_RESPONSE:
                    return {
                        ...state,
                        quizQuestions: action?.payload?.data
                    }
         case types.SET_SEARCH_BY_CID_RES:
            let payloadData=action?.payload?.data || [];
            let shortedCrsList =[];
            let arcCrouse=state?.archiveCourses || [];
            try{
                 shortedCrsList = payloadData.sort(function (a, b) {
                    const textA = a.title.toUpperCase();
                    const textB = b.title.toUpperCase();
                    // eslint-disable-next-line no-nested-ternary
                    return textA < textB ? -1 : textA > textB ? 1 : 0;
                  });
                  shortedCrsList = shortedCrsList?.filter(
                    (item) => item?.filepath !== '0' && item.cid !== 0
                  );
                }catch(e){}
                arcCrouse[action?.payload?.id||-1]={...arcCrouse[action?.payload?.id||-1],selectedList:shortedCrsList};
                    return {
                        ...state,
                        selectedArchiveCrs: shortedCrsList,
                        archiveCourses:arcCrouse
                    }
        case types.GET_ARCHIVE_SEARCH_RES:
                let payload=action?.payload?.data || [];
                let shortedList =[]
                try{
                       shortedList = payload.sort(function (a, b) {
                        const textA = a.title.toUpperCase();
                        const textB = b.title.toUpperCase();
                        // eslint-disable-next-line no-nested-ternary
                        return textA < textB ? -1 : textA > textB ? 1 : 0;
                      });
                      shortedList = shortedList?.filter(
                        (item) => item?.filepath !== '0' && item.cid !== 0
                      );
                    }catch(e){}
                    return {
                        ...state,
                        isLoading:false,
                        archiveSearchCrs:shortedList 
                    }


        default:
            return state;
    }
}


export default ceAssetsInfoReducer