import React, { useState, useEffect, useMemo } from 'react'
import { Button } from '@material-ui/core';
import './globalPropertyPanel.scss';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import TextField from '@material-ui/core/TextField';
import { FormControlLabel} from '@material-ui/core';
import RadioSelect from '../../../components/ui/RadioSelect/RadioSelect';
import Checkbox from '@mui/material/Checkbox';
import Slider from '@mui/material/Slider';
import { useSelector, useDispatch } from 'react-redux';
import * as globalStyleAction from '../../../redux/actions/globalStyleAction';
import * as courseEditAction from '../../../redux/actions/courseEditAction';
import * as courseInfoAction from '../../../redux/actions/courseInfoAction';
import UploaderPanel from '../UploaderPanel/UploaderPanel'
import Datahelper from '../../../utils/DataHelper';
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';
import FormGroup from '@mui/material/FormGroup';
import ResizableTextArea from "../../../components/ui/ResizableTextArea/ResizableTextArea";
import CustomCheckbox from "../../../components/ui/CustomCheckbox/CustomCheckbox";
import QuizConfig from '../../../components/ui/QuizConfig/QuizConfig';
import BuildHelper from "../../../utils/BuildHelper"
import PlayerConstants from '../../../utils/PlayerConstants';
import Quizzes from '../../../components/content/Quizzes/Quizzes';
import QuizzesPanel from '../PropertyPlaceHolder/QuizzesPanel'
import AppButton from '../../../components/ui/AppButton/AppButton';
import MuiIcons from '../../../components/ui/MuiIcons/MuiIcons';
import CustomSelect from '../../../components/ui/CustomSelect/CustomSelect';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import RichTextEditor from '../RichTextEditor/RichTextEditor';
import Switch from '@material-ui/core/Switch';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  }));

const GlobalPropertyPanel = () => {
    const {globalStyle, courseInfo, ceAssetsInfo} = useSelector(state => state);
    const userInfo = useSelector(state => state?.userInfo);
    const dispatch = useDispatch();
    const [style, setStyle] = useState(globalStyle);
    const [isUpdate, setIsUpdate] = useState(false);
    const [isPreTest, setIsPreTest] = useState({
        isModel:false,
        isPreview:false
    });
    // const [isQuiz, setIsQuiz] = useState(false);

    const handleComSpace = (e) => {
        var obj = {
            ...globalStyle?.comspace,
            [e.target.name]: e?.target?.value
        };
        setStyle({ ...style, comspace: obj });
    }


    useEffect(()=>{
        dispatch(globalStyleAction.updateGlobalStyle({...style}));
        if(!isUpdate) setIsUpdate(true);
    },[style])

    const globalFontChange = (e) => {
        setStyle({ ...style, font: e });
    }

    const topicStyleChange = (e) => {
        let obj = {
            ...style.topic,
            [e.target.name]: e?.target?.value
        }
        setStyle({ ...style, topic: obj });
    }

    
    const handleGlobalChecked = (e) => {
        let obj = {
            ...style,
            [e.target.name]: e?.target?.checked
        }
        setStyle({ ...obj});
    }

    const handleClose = () => {
        dispatch(courseInfoAction.setSideBar(true));
        dispatch(courseEditAction.toggleDrawer({ open: false, drawerWidth: 0 }));
    }

    const sliderChange = (e) => {
        let isCheck;
        if (e?.target?.value === 'linear') {
            isCheck = true;
        } else { isCheck = false; };
        setStyle({ ...style, isLinear: isCheck });
    }

    const handleInstructionText = (e) => {
        setStyle({ ...style, [e.target.name]: e?.target?.checked });
    }

    const handleSubmit = () => {
        if (isUpdate) {
             handleSave();
            dispatch(courseEditAction.toggleDrawer({ open: false, drawerWidth: 0 }))
        }
    }

    const handleTradeTutor=(e)=>{
        let obj = {
            ...style,
            headers:{
                ...style?.headers,
                [e.target.name]: e?.target?.checked
            }
        }
        setStyle({ ...obj});
    }


    const handleName=(e)=>{
        let obj = {
            ...style,
            [e.target.name]: e?.target?.value||""
        }
        setStyle({ ...obj});
    }

    const handleIsPreTest=(e)=>{
        let obj = {
            ...style,
            pre_test:{
                ...style?.pre_test||{},
                is:e?.target?.checked
            }
        }
        setStyle({ ...obj});
    }


    
    const handleTheme=(e)=>{
        let obj = {
            ...style,
            [e.target.name]: e?.target?.value||"",
            font:PlayerConstants.palette[e?.target?.value]['font']
        }
        setStyle({ ...obj});
    }

 const handleDefaultFontSize=(e)=>{
    var obj ={
        ...globalStyle?.comspace,
        'isfs':e?.target?.checked
    };
    setStyle({ ...style, comspace: obj });
 }
 const handleMedia=(data,name)=>{
    try{
    let obj={...style,[name]:data?.name||"*"}
    setStyle({ ...obj});
    }catch(e){}
 }

 const handleIsAttestation=(e)=>{
    try{
    let obj={...style,"attest":{...style.attest,[e.target.name]:e?.target?.checked}}
    setStyle({ ...obj});
    }catch(e){}
 }

 const handleAttestation=(value)=>{
    try{
    let obj={...style,"attest":{...style.attest,t:value}}
    setStyle({ ...obj});
    }catch(e){}
 }

 const handleGlobalLrnSettings=(e,lrn)=>{
    let lrnData=style?.lrnSettings||{}
    lrnData[lrn]= {...lrnData[lrn],[e.target.name]: e?.target?.value};
    setStyle({ ...style, lrnSettings:lrnData });
 }

 const handleGlobalLrnSettingsCheck=(e,lrn)=>{
    let lrnData=style?.lrnSettings||{}
    lrnData[lrn]= {...lrnData[lrn],[e.target.name]: e?.target?.checked||false};
    setStyle({ ...style, lrnSettings:lrnData });
 }


 const handleTogglePreview =(name)=>{
    setIsPreTest({
        ...isPreTest,
        [name]:!isPreTest[name]
    })
 }
 const updatePreTestQuestion=(data)=>{
    const updatedStyle={
        ...style,
        pre_test:{
            ...(style?.pre_test||{}),
            d:{...data},
            // c:data?.config||{}
        }
    }
    setStyle({...updatedStyle});
    dispatch(globalStyleAction.updateGlobalStyle({...updatedStyle}));
    setIsUpdate(true);
 }

 const socialShare=(e)=>{
    setStyle({
        ...style,
        info:{
            ...(style?.config||{}),
            cert:{
                ...(style?.info?.cert||{}),
                soc_publish:e?.target?.checked
            }
        }
    })
 }

 const handleSave = () => {
    if (isUpdate) {
      let preTestData = { ...style };
      if (preTestData?.pre_test?.d?.items?.length > 0) {
        preTestData = {
          ...preTestData,
          pre_test: {
            ...preTestData.pre_test,
            d:{ ...preTestData?.pre_test?.d, items:preTestData?.pre_test.d?.items?.map(e => e?._id)}
          }
        };
        handleTogglePreview('isModel');
      }
  
      dispatch(globalStyleAction.globalStyleRequest(preTestData));
    }
  };
  

 const handleCertificate=(e)=>{
    setStyle({
        ...style,
        info:{
            ...(style?.config||{}),
            cert:{
                ...(style?.info?.cert||{}),
                c_type:e?.value
            }
        }
    })
 }

    const quizConfigClear =()=>{
        let lrnData=style?.lrnSettings||{}
        lrnData['Quizzes']= {...lrnData['Quizzes'],iscfg:false};
        setStyle({ ...style, lrnSettings:lrnData });
    }

    const preTestModel = useMemo(() => (
        <BootstrapDialog
            onClose={() => handleTogglePreview('isModel')}
            aria-labelledby="customized-dialog-title "
            className='pre-test-modal'
            open={isPreTest?.isModel}
        >
            <DialogTitle sx={{ m: 0, p: 2 }} className='pre-header' id="customized-dialog-title">
                <AppButton
                    size="small"
                    theme="primary1_transparent"
                    className="action-btn"
                    onClick={() => handleTogglePreview('isPreview')}
                    disabled={false}
                >
                    {isPreTest?.isPreview ? 'Edit' : 'Preview'}
                </AppButton>
            </DialogTitle>
            <DialogContent dividers className='pre-test-container prop-panel'>
                {!isPreTest?.isPreview ? (
                    <div className='edit-panel'>
                        <ResizableTextArea
                            style={{ width: '49%' }}
                            minRows={1}
                            name="t"
                            value={style?.pre_test?.t || ''}
                            onChange={(e) => setStyle({
                                ...style,
                                pre_test: {
                                    ...style?.pre_test,
                                    t: e?.target?.value
                                }
                            })}
                            label='Pre-Test Instructions'
                        />
                        <QuizzesPanel
                            items={style?.pre_test?.d?.items ||[]}
                            config={style?.pre_test?.d?.config ||null}
                            pers={style?.pre_test?.d?.pers}
                            handleProperties={(value) => updatePreTestQuestion(value)}
                            isPreTest={true}
                            t_Id={courseInfo?.topic?.id}
                            c_Id={window?.ce?.rlm?.config_id}
                        />
                    </div>
                ) : (
                    <div className='preview-panel'>
                        <Quizzes
                            items={style?.pre_test?.d?.items ||[]}
                            config={style?.pre_test?.d?.config ||null}
                            pers={style?.pre_test?.d?.pers}
                            isPreTest
                        />
                    </div>
                )}
            </DialogContent>
            <DialogActions>
                <AppButton
                    size="small"
                    theme="secondary2"
                    className="action-btn"
                    onClick={() => handleTogglePreview('isModel')}
                    disabled={false}
                >
                    Cancel
                </AppButton>
                <AppButton
                    size="small"
                    theme="secondary2"
                    className="action-btn ml-2"
                    onClick={handleSave}
                    disabled={false}
                >
                    Save
                </AppButton>
            </DialogActions>
        </BootstrapDialog>
    ), [isPreTest, style?.pre_test, ceAssetsInfo, courseInfo, userInfo, globalStyle?.pre_test]);

    return (
        <div>
            <div className="prop-panel card global-property">
                <div className='row global-continuer'>
                <div className='col-lg-12  padding' >
                   <ResizableTextArea value={style?.title||""}  minRows={1}  name="title" onChange={(e)=>handleName(e)} placeholder="  Enter the course welcome..." />
                </div>
                <div className='col-lg-4 col-md-24 col-sm-12 padding v-small-uploader' >
                        <label className="label" id="demo-radio-buttons-group-label padding">Banner Image:</label>
                           <UploaderPanel  accept={'image'} fileName={style?.homeImage || null} name={`${'banner'}_${Datahelper.getTime()}-image`} setUploadedData={(d)=>handleMedia(d,"homeImage")} isAssetUploader />
                    </div>

                    <div className='col-lg-4 col-md-24 col-sm-12 padding v-small-uploader' >
                        <label className="label" id="demo-radio-buttons-group-label padding">Powered by:</label>
                           <UploaderPanel  accept={'image'} fileName={style?.poweredby || null} name={`${'banner'}_${Datahelper.getTime()}-image`} setUploadedData={(d)=>handleMedia(d,"poweredby")} isAssetUploader />
                    </div>

                    <div className='col-lg-4 col-md-24 col-sm-12 crs-structure'>
                          <label className="label" id="demo-radio-buttons-group-label padding">Course structure : </label>
                            <RadioSelect
                                options={[
                                    {name:'Linear',value:'linear',label:'Linear'},
                                    {name:'Tree',value:"tree",label:'Tree'}
                                ]}
                                direction='horizontal'
                                name="radio-buttons-group"
                                className='row-radio-group'
                                onChange={(e) => sliderChange(e)}
                                color= '#000'
                                selectedValue={style?.isLinear ? 'linear' : 'tree'}
                            />
                            <hr className='w-100'/>
                             <CustomCheckbox 
                             checked={globalStyle?.snc||null}
                             name="snc" 
                             onChange={(e) => handleGlobalChecked(e)} 
                             label="Strict Navigation"
                            //  className="px-2"
                          />
                    </div>
                    <hr/>
                    <div className='col-lg-6 col-md-24 col-sm-12'>
                      <label className="label" id="demo-radio-buttons-group-label padding">Header:</label>
                      <FormGroup row className='tradetutor'>
                        <CustomCheckbox 
                         checked={style?.headers?.m} name="m" onChange={(e) => handleTradeTutor(e)}
                         label="Course Map"
                        />
                      <CustomCheckbox 
                         checked={style?.headers?.s} name="s" onChange={(e) => handleTradeTutor(e)}
                         label="Search"
                        />
                         <CustomCheckbox 
                         checked={style?.headers?.u} name="u" onChange={(e) => handleTradeTutor(e)} 
                          label="Updates"
                        />
                         <CustomCheckbox 
                         checked={style?.headers?.n} name="n" onChange={(e) => handleTradeTutor(e)} 
                          label="CE Notes"
                        />
                        <CustomCheckbox 
                         checked={style?.headers?.v} name="v" onChange={(e) => handleTradeTutor(e)}
                          label="Vignettes"
                        />
                         <CustomCheckbox 
                          checked={style?.headers?.c} name="c" onChange={(e) => handleTradeTutor(e)}
                          label="Certificate"
                        />
                    </FormGroup>
                    </div>
                    <div className='col-lg-6'>
                        <label className="label" >Layout :</label>
                        <div className='row'>
                                <div className='col-lg-3  col-md-12 col-sm-12 padding'>
                                <label className="label">Top </label>
                                <Slider className="ce-slider" value={style?.comspace?.mt || 3} name="mt" onChange={(e) => handleComSpace(e)} aria-label="Default" valueLabelDisplay="auto" step={1}
                                    min={0}max={10} />
                            </div>
                            <div className='col-lg-3 col-md-12 col-sm-12 padding'>
                                <label className="label">Bottom </label>
                                <Slider className="ce-slider" value={style?.comspace?.mb || 3} name="mb" onChange={(e) => handleComSpace(e)} aria-label="Default" valueLabelDisplay="auto" step={1}
                                    min={0} max={10} />
                            </div>
                            
                            <div className='col-lg-3 col-md-12 col-sm-12 padding'>
                                <label className="label"  >Left </label>
                                <Slider className="ce-slider" value={style?.comspace?.pl || 0} name="pl" onChange={(e) => handleComSpace(e)} aria-label="Default" valueLabelDisplay="auto" step={1}
                                    min={0}max={10} />
                            </div>
                            <div className='col-lg-3 col-md-8 col-sm-12 padding'>
                                <label className="label"  >Right </label>
                                <Slider className="ce-slider" value={style?.comspace?.pr || 0 } name="pr" onChange={(e) => handleComSpace(e)} aria-label="Default" valueLabelDisplay="auto" step={1}
                                    min={0} max={10} />
                            </div>

                        </div>
                    </div>
                    <hr/>
                    {(PlayerConstants?.ARCHIVE_VIDEO_ENABLE_LIST?.includes(userInfo?.user?.email||'')) &&
                        <div className='col-lg-6  col-md-12 font-family'>
                            <FormControl size='medium' className='font-control' >
                                <InputLabel id="demo-select-small" className='font-label'>Theme</InputLabel>
                                <Select
                                    labelId="demo-select-small"
                                    id="demo-select-small"
                                    value={style?.theme ||'ce_theme'}
                                    label="Theme"
                                    onChange={(e) => handleTheme(e)}
                                    className="dropdown-font"
                                    name='theme'
                                >
                                    {themeStyle?.map((item, index) => <MenuItem key={index} className='select-option-list'  value={item.value}>{item.name}</MenuItem>)}

                                </Select>
                            </FormControl>
                        </div>}
                        {/* <div className='col-lg-6  col-md-12 font-family'>
                         <FormControl size='medium' className='font-control' >
                                <InputLabel id="demo-select-small" className='font-label'>Version</InputLabel>
                                <Select
                                    labelId="demo-select-small"
                                    id="demo-select-small"
                                    value={style?.version ||null}
                                    label="Version"
                                    onChange={(e) => handleName(e)}
                                    className="dropdown-font"
                                    name='version'
                                >
                                     <MenuItem key={0} className='select-option-list'  value={20}>Default</MenuItem>
                                    <MenuItem key={0} className='select-option-list'  value={30}>30</MenuItem>
                                </Select>
                            </FormControl>
                        </div> */}
                        <div className='col-lg-6  col-md-12  font-family pt-3'>
                            <FormControl size='medium' className='font-control' >
                                    <InputLabel id="demo-select-small" className='font-label'>Font Family</InputLabel>
                                    <Select
                                        labelId="demo-select-small"
                                        id="demo-select-small"
                                        value={style?.font ||'RobotoLight'}
                                        label="FontStyle"
                                        onChange={(e) => globalFontChange(e.target.value)}
                                        className="dropdown-font"
                                        name='fs'
                                    >
                                        {fontFamilyList.map((item, index) => <MenuItem key={index} className='select-option-list'  value={item.value}>{item.name}</MenuItem>)}

                                    </Select>
                                </FormControl>
                          </div>
                    <hr/>
                    {/* <div className='col-lg-6  col-md-12 d-flex  justify-content-between  global-font'>
                     <lable className="font-size-label">
                        <FormControlLabel label="Apply global font" labelPlacement="end" control={<Checkbox name={'default'} checked={((style?.comspace?.isfs)||false)} onChange={(e) => handleDefaultFontSize(e)} />} />
                        <Tooltip className="font-info" title="The modifications will be universally applied across all learning elements, regardless of their respective styling. Notably, custom headers such as H1A, H2A, and so forth will remain exempt from the change." placement="top-start">
                            <Button> <InfoIcon /></Button>
                        </Tooltip>
                     </lable>
                    </div> */}
                    <div className='col-lg-6  col-md-12 global-font global-font-box'>
                        <lable className="font-size-label">
                        <CustomCheckbox 
                        name={'default'} 
                        checked={((style?.comspace?.isfs)||false)} 
                        onChange={(e) => handleDefaultFontSize(e)}
                        label="Apply global font"
                        />
                            <Tooltip className="font-info" title="The modifications will be universally applied across all learning elements, regardless of their respective styling. Notably, custom headers such as H1A, H2A, and so forth will remain exempt from the change." placement="top-start">
                                 <InfoIcon />
                            </Tooltip>
                        </lable>
                        <TextField
                                id="filled-number"
                                type="number"
                                className="topic-font-global w-100"
                                variant="filled"
                                name='gfs'
                                // disabled={!(style?.comspace.gfs)}
                                InputProps={{ inputProps: {min:10, max: 30 } }}
                                value={style?.comspace?.gfs || 24}
                                onChange={(e) =>handleComSpace(e)}
                            />
                    </div>
                     
                    <div className='col-lg-6 topic-styles'>
                        <label className="label">Topic title :</label>
                        <div className='row'>
                        <div className='col-lg-6 col-md-24 col-sm-12'>
                        <TextField
                            id="filled-number"
                            label="Font Size"
                            type="number"
                            className="topic-font-global"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={style?.topic?.fs || 48}
                            variant="filled"
                            name='fs'
                            onChange={(e) => topicStyleChange(e)}
                        />
                    </div>
                    <div className='col-lg-6  col-md-24 col-sm-12'>
                        <TextField type='color' id="outlined-basic" spellCheck={false} name='color' label="Color" variant="outlined" defaultValue={style?.topic?.color} onChange={(e) => topicStyleChange(e)} />
                     </div>

                        </div>
                    </div>
                   

                    {/* <div className='col-lg-6  col-md-12 d-flex  justify-content-between  global-font'>
                        <lable className="font-size-label">
                            <FormControlLabel label="Quiz pass threshold" labelPlacement="end" control={<Checkbox name={'strict'} checked={((style?.lrnSettings["Quizzes"]?.strict)||false)} onChange={(e) => handleGlobalLrnSettingsCheck(e,"Quizzes")} />} />
                        </lable>
                    </div> */}
                    {/* <hr/> */}
                    {/* <div className='col-lg-6  col-md-12 global-font global-font-box'>
                       <lable className="font-size-label">
                            <FormControlLabel label="Quiz pass threshold" className='quiz-label' labelPlacement="end" control={<Checkbox name={'strict'} checked={((style?.lrnSettings["Quizzes"]?.strict)||false)} onChange={(e) => handleGlobalLrnSettingsCheck(e,"Quizzes")} />} />
                        </lable>
                          <TextField
                                id="filled-number"
                                type="number"
                                className="topic-font-global w-100"
                                variant="filled"
                                name='min'
                                // disabled={!(style?.comspace.gfs)}
                                InputProps={{ inputProps: {min:10, max: 100 } }}
                                value={style?.lrnSettings["Quizzes"]?.min|| 24}
                                onChange={(e) =>handleGlobalLrnSettings(e,"Quizzes")}
                            />
                    </div> */}
                    <hr/>
                    { BuildHelper.isConfig() && <> <div className='col-lg-8  col-md-12'>
                       {!style?.lrnSettings["Quizzes"]?.iscfg &&  <CustomCheckbox 
                            label="Quiz Configuration"
                            checked={style?.lrnSettings["Quizzes"]?.iscfg}
                            name="iscfg"
                            onChange={(e)=>handleGlobalLrnSettingsCheck(e,"Quizzes")}
                        />}
                        <CustomCheckbox 
                            checked={style?.pre_test?.is||null}
                            name="is" 
                            onChange={(e) => handleIsPreTest(e)} 
                            label="TestOut"
                        />
                       {style?.pre_test?.is && <MuiIcons iconName="edit" name='isModel' className='pre-test-edit' color="#000" onClick={()=>handleTogglePreview ('isModel')} preText="View" />}
                    </div>
                    {(PlayerConstants?.CERTIFICATE_ENABLE_LIST?.includes(userInfo?.user?.email||'')) &&<div className='col-lg-4  col-md-12'>
                     <CustomSelect
                        value={style?.info?.cert?.c_type||'ce'}
                        options={certificateTypes}
                        onChange={handleCertificate}
                        label='Select Certificate'
                        style={{width:'100%'}}
                    />
                    </div>}
                    <hr/>
                    <div className='col-lg-12  col-md-12 global-font global-font-box'>
                        <CustomCheckbox 
                             checked={globalStyle?.info?.cert?.soc_publish||null}
                             name="ln" 
                             onChange={(e) => socialShare(e)} 
                             label="Social Share"
                          />
                          <CustomCheckbox 
                             checked={globalStyle?.ff_r}
                             name="ff_r" 
                             onChange={(e) => handleGlobalChecked(e)} 
                             label="Fast Forward & Rewind"
                          />
                        <lable className={`attestation ${(style?.attest?.is) && "d-flex p-0 py-2"}`}>
                        <CustomCheckbox 
                             checked={(style?.attest?.is)}
                             name="is" 
                             className='quiz-label'
                             onChange={(e) => handleIsAttestation(e)} 
                             label="Attestation"
                          />
                          {style?.attest?.is && <FormControlLabel
                            value={style?.attest?.begin}
                            name="begin" 
                            // className='custom-switch'
                            control={<Switch   onChange={(e) => handleIsAttestation(e)}  checked={style?.attest?.begin}  className="custom-switch" />}
                            label={!style?.attest?.begin ?"at the end":"in the beginning"}
                            labelPlacement="start"
                            />}
                        </lable>
                        {(style?.attest?.is) && 
                         <RichTextEditor
                         styles={{ minHeight: 80 }} 
                         data={style?.attest?.t||""}
                         eventHandler={(value) => handleAttestation(value)}
                         />
                        }
                    </div>
                    <hr/>
                    {style?.lrnSettings["Quizzes"].iscfg && <div>
                         <lable className="label">Quiz Configuration</lable>
                            <QuizConfig
                              configObj={style?.lrnSettings["Quizzes"]?.config||null}
                              glob={style?.lrnSettings["Quizzes"]}
                              handleConfig={(cf)=>handleGlobalLrnSettings({target:{name:"config",value:cf}},"Quizzes")} 
                              handleReset={quizConfigClear}
                              />
                        </div>} </>}
    
                    {/* <div className='col-lg-12 col-md-12 col-sm-12 ins-box'>
                        <FormControlLabel label="Show Instructions :" labelPlacement="start" control={<Checkbox name={'isInstruction'} checked={style?.isInstruction} onChange={(e) => handleInstructionText(e)} />} />
                    </div> */}
                </div>
            </div>
            <div className='property-action-info'>
                <Button className='cancel-btn' onClick={handleClose}>Cancel</Button>
                <Button className='submit-btn' disabled={!isUpdate} onClick={handleSubmit} >Save </Button>
            </div>
           {style?.pre_test && preTestModel}

        </div>
    )
}


/*
* Global Font Family  It's used at TemplateRender.js -ClassName
* ClassName Style's are added at slidertreemenu.scss
* in the 'value', we use "|" to split sections into regular and heading font.
*/
export const fontFamilyList = [
    { name: 'Roboto Light', value: 'RobotoLight' },
    { name: 'Roboto Regular', value: 'RobotoRegular' },
    { name: 'Roboto Medium', value: 'RobotoMedium' },
    { name: 'Roboto Bold', value: 'RobotoBold' },
    { name: 'Work Sans Light', value:'WorkSansLight'},
    { name: 'Work Sans Variable', value:'WorkSansVariable'},
    { name: 'Work Sans Regular', value:'WorkSansRegular'},
    { name: 'Work Sans Medium', value:'WorkSansMedium'},
    { name: 'Work Sans Bold', value: 'WorkSansSemiBold' },
    { name: 'font-work-sans-light', value: 'WorkSansLight' },
    { name: 'Times New Roman', value: 'times-new-roman' },
    { name: 'Gilroy & Bitter', value: 'BitterRegular|GilroyMedium' },// regular and heading
]

export const themeStyle =[
    {name:"CE-Theme",value:"ce_theme"},
    {name:"Skill-Theme",value:"skill_theme"}
]

export const certificateTypes =[
    {label:'Skill Certificate',value:'skill'},
    {label:'Ce Certificate',value:'ce'},
    {label:'Schar Certificate',value:'schar'},
    // {label:'Kings Certificate',value:'kings'}
]

// export const defaultStyle = {
//     comspace: { mt: 3, mb: 3, pl: 0, pr: 0, gfs:null },
//     font: 'default',
//     topic: { fs: 48, color: '#000' },
//     isLinear: false,
//     isInstruction: true
// }
export default GlobalPropertyPanel