import React, { useEffect, useState } from 'react'
import CommentIcon from '@material-ui/icons/Comment';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import './quickaccessbar.scss'
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types'
import * as courseEditAction from '../../../redux/actions/courseEditAction'
import * as courseInfoAction from '../../../redux/actions/courseInfoAction'
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Button } from '@material-ui/core';
import DataHelper from '../../../utils/DataHelper';
import PlayerConstants from '../../../utils/PlayerConstants';
import ACLHelper from '../../../utils/ACLHelper';
import * as ceAssetsInfoAction from '../../../redux/actions/ceAssetsInfoAction'
import PredefinedTemplate from '../../../utils/PredefinedTemplate';
import CustomSweetAlert from "../../../components/ui/CustomSweetAlert/CustomSweetAlert";
import useToastNotifications from '../../../utils/hooks/useToastNotifications'

const QuickAccessBar = ( { target } ) => {
    
    const { showSuccess } = useToastNotifications();
    const dispatch = useDispatch()

    const utility = useSelector(state => state.utility);
    const courseInfo = useSelector( state => state.courseInfo );
    const userInfo = useSelector(state => state.userInfo);
    const courseEdit = useSelector(state => state.courseEdit);
    const trackInfo = useSelector(state => state.courseTracking);
    const ceAssetsInfo = useSelector(state => state.ceAssetsInfo);
    const lrn_Id = ceAssetsInfo?.itemInfo?._id;
    const c_Id = window?.ce?.rlm?.config_id;
    const t_Id = courseInfo?.topic?.id;

    const [isAidEditEnabled,setAidEditEnabled] = useState(false);
    const [ isDeleteConfirm, setDeleteConfirm ] = useState( false );
    const [minAidAlert,setMinLAidAlert] = useState(false);
    const [selectedTemp,setSelectedTemp] = useState({});
    const [isTemplate,setIsTemplate] = useState(false);
    let curItem = courseInfo?.topic?.lrncontent?.find( item => { return item?._id === target?.element?.id } )
    const [ currentTarget, setCurrentTarget ] = useState( { id: target?.element?.id, data: curItem } );

    const cloneObj = {
        lrnid: curItem?._id,
        topicid: courseInfo?.topic.id,
        configid: window.ce?.rlm?.config_id,
        userid: curItem?.author?.id,
        name: window?.ce?.ceuser?.firstname|| userInfo?.user?.firstname,
        clientid:window?.ce?.ceuser?.pvtid
    }

    /** check whether the type is config/course */
    const isConfig = () => {
        return window?.ce?.ceuser?.type === 'config' ? true : false
    }


    useEffect(()=>{
        if (ACLHelper.isFeatureEnabled(1,2) && courseEdit?.itemInfo?._id===target?.element.id && courseEdit?.panel === 2 ) {
             openDrawer(2,25); 
        }
    },[courseEdit?.isOpen])
 
    const openDrawer = (viewpanel, width ) => {
        let panelWidth=width
        const mediaEle = document.querySelectorAll( 'video, audio' )
        if ( mediaEle.length !== 0 )
            for ( const media of mediaEle ) {
                media.pause()
            }
        if(viewpanel===2){
            if(PlayerConstants?.BIGGER_CONTENT_PANELS?.indexOf(target?.cmp?.name)!== -1) panelWidth=80;
            if(PlayerConstants?.CONTENT_PANELS?.indexOf(target?.cmp?.name) !==  -1) panelWidth=40; 
        }
        dispatch( courseEditAction.toggleDrawer( { open: true, target: target, itemInfo: curItem, panel: viewpanel, drawerWidth: panelWidth } ) )
    }

    const onStatusChange = ( event ) => {
        curItem.status = Number( event.target.value );
        let updateVal = { id: curItem._id, status: Number( event.target.value ) }
        setCurrentTarget( { id: target?.element.id, data: curItem } );
        if(!isAidEditEnabled){//this check and this payload are only for configuring non-customized learning elements
          updateVal={
            lrnid: updateVal?.id,
            configid: window?.ce?.rlm?.config_id,
            userid: window?.ce?.ceuser?.uid,
            name:`${userInfo?.user?.firstname} ${userInfo?.user?.lastname}`,
            type:2,
            status:updateVal?.status,
          }
        }
        dispatch( courseEditAction.updateLearningAid( { update: updateVal, item: curItem } ) )
    }

    useEffect( () => {
        curItem = courseInfo?.topic?.lrncontent?.find( item => { return item?._id === target?.element?.id } )
        setCurrentTarget( { id: target?.element?.id, data: curItem } );
        setAidEditEnabled(isEditEnabled(curItem))
    }, [ target, courseInfo?.topic ] )


    const handleChange = (event,curItem) => {
        curItem.isSwitchOn = event.target.checked
        // console.debug("On Change currrent=========",curItem);
        setCurrentTarget( { id: target?.element.id, data: curItem } );
    }


   // handler for video submission (ArchiveVideo and Video)
    const handleVideoSubmission=()=>{
        return{ ...curItem.props, cuepoints: [] } //save component properties without cue points
    }

    // handler for quiz submission (Quizzes)
    const handleQuizSubmission = () => {
        let items=[...(curItem?.props?.items||[])];
        items=items?.map(data=>(data?._id))?.filter(Boolean);
        // save component properties along with quiz IDs
        return {...curItem?.props,items:items}
    };

    const saveClone = () => {
        delete curItem.props.handler
        delete curItem.props.track
        delete curItem?.isSwitchOn
        function defaultProp(){
            return curItem?.props
        }
        const handlers = {
            "ArchiveVideo": handleVideoSubmission,
            "Video": handleVideoSubmission,
            "Quizzes": handleQuizSubmission,
            "default": defaultProp
        };
        const submitHandler = handlers[(curItem?.name ?? curItem?.cname)] || handlers["default"];
        const LRNCloneData =  submitHandler();
        const cloneData = Object.assign( cloneObj, { props: LRNCloneData } )
        dispatch(courseInfoAction.cloneLearningAid(cloneData))
        /** 
         * Check for Clone API Success response then enable Editor options
         * Given Directly
         * TODO
         */
        setAidEditEnabled(true)
    }

    const removeLearningAid = ( id ) => {
        setDeleteConfirm( true )
    }
    // updating publish latest data in courseEditingInfo reducer
    const updateLatestPublish=(val)=>{
        dispatch(courseEditAction.publishCourseInfo(val))
    }

    const deleteConfirmation = () => {
        console.debug( "==========", target?.element?.id );
        deleteLearingAid(target?.element?.id,courseInfo?.topic?.markup,target?.cmp?.compid)
        setDeleteConfirm( false );
    }

    const onDeleteCancel = () => {
        setDeleteConfirm( false )
    }


    const deleteLearingAid = async (id,markup,compid)=>{
       
        try{
            const markuplist = markup.split('</section>');
            let selectedcmpId =""
            /**
             * Empty item added into last so removing that
             */
            let emptyItem = markuplist[markuplist.length-1];
            if(emptyItem === ''){
                let index = markuplist.indexOf(emptyItem)
                markuplist.splice(index,1)
                
            }

            if(target?.cmp?.compid){
              selectedcmpId = compid.split('_')[1];
               let newMarkupList = [];
               markuplist.forEach((item)=>{
                   let fcompid = item.replace(/\D/g, '');
                   if(item !== '' && fcompid !== selectedcmpId){
                    newMarkupList.push(item+'</section>')
                   }
               });
               console.debug("updated",markuplist,markuplist.length);
            if(markuplist.length === 1){
                console.debug("Minimum 1 Learning Aid should be there for the topic");
                setMinLAidAlert(true)
                return;
            }else{
                let data = {
                    lrnid:id,
                    markup:newMarkupList.join(''),
                    topicid:courseInfo?.topic?.id,
                }
                console.debug("inside dispatch data",data);

                if(DataHelper.isPublish()){
                    let lrnProp=courseInfo?.topic?.lrncontent?.find(e=>e?._id===target?.element?.id);
                    DataHelper.publishFormatting({...lrnProp},courseEdit,courseInfo,trackInfo,updateLatestPublish,{type:3,markup:data?.markup,itemInfo:lrnProp});
                    dispatch(courseInfoAction.publishDeleteLRN(data));
                    showSuccess(  'LearningAid Removed Successfully');
                    return;
                }
                dispatch(courseEditAction.deleteLearningAid(data));
            }

            }
        }catch(e){
            console.log("e",e)
        }
    }


    const closeAlertBox = () =>{
        setMinLAidAlert(false)
    }

    
    const getLearningAidId =()=>{
        try{
            return DataHelper.getLearningAidId(courseInfo?.courseAttributes?.coursename,
                courseInfo.topic?.nodeId,target?.cmp?.compid,target?.element?.id)
        }catch(e){
            console.log(e);
        }
        
    }

    const copyToClipboard = ()=>{
        navigator.clipboard.writeText( getLearningAidId()+":"+target?.element?.id )
        showSuccess( 'Learning Aid id copied successfully');
    }
  /**
   * 
   * @param {*} logs 
   * @returns 
   * We have logs [-1, -2,-3] in the LRN, which indicate customized
   * -1: Customized LRN (applied during cloning)
   * -2: New LRN (created after cloning)
   * -3: LRN after applying the template
   */
    const isClonedAid = (logs)=>{
        if(logs.length > 0){
            let found = logs.find((el)=>{
                return el.status === -1 || el.status === -2 || el.status === -3
            });
            // console.debug("found",found)
            if(found){
                return true
            }
        }
        return false
    }

    const isEditEnabled = (curItem) =>{
        if(isConfig()){
            if(curItem?.logs && curItem.logs.length > 0){
                return isClonedAid(curItem.logs);
                // (curItem.logs[0].status === -1 || curItem.logs[0].status === -2 ) ? true:false;
            }
            return false;
        }else{
            return true;
        }
    }


    const getMappedStatus = ()=>{
        // 0 cancel removed (13/08/24) based on Kim's request
        let orders = [-1,2,3,1]; // Dont change this ..its a requirment 
        let mapped =  orders.map((key)=>{
            return PlayerConstants?.STATUS_LRN_AIDS[key]
        });
        return mapped;
    }

    /** saving get the Props data updated in this courseInfo.topic.lrncontent */
    const handleSubmit = (e) =>{
     try{
        let props=curItem.props;
        props.edit_idx=null;
        
        if(DataHelper?.isPublish()){
           if(props?.id) DataHelper.publishFormatting(props,courseEdit,courseInfo,trackInfo,updateLatestPublish)
        }else{
            dispatch( courseInfoAction.saveLearningAid( { id:target?.element?.id, data:props } ) );
        }
       //saving get updated on courseEdit
    //     if(ceAssetsInfo?.linkedAsset && target?.cmp?.name==="ImageMapper"){
    //         if(ceAssetsInfo?.linkedAsset?.lrn_Id===lrn_Id){
    //             let updateItem=DataHelper?.getAssetsInfo(ceAssetsInfo?.linkedAsset?.data,c_Id,lrn_Id,t_Id)
    //             updateItem={...updateItem,clientid:userInfo?.user?.pvtid}
    //             dispatch(ceAssetsInfoAction?.ceAssetsLinkedRequest(updateItem));
    //             dispatch(ceAssetsInfoAction?.ceAssetsLinkedResponse(updateItem));
    //         }
    //    }
       showSuccess( 'Learning Aid Updated successfully');
       e.stopPropagation();
     }catch(e){}
    }

    const onTemplateChange =(e)=>{
    try{
       const template = PredefinedTemplate[target.cmp.name][e?.target?.value];
        let selectedObj={ ...selectedTemp,[target.element.id]:e?.target?.value};
        const updated={...curItem?.props, styleObj:{...template?.styleObj,id:e?.target?.value}};
        setSelectedTemp(selectedObj);
        if(target?.cmp?.name==="ScenarioBlock" && !template?.styleObj?.name)setIsTemplate(true);
        dispatch(courseInfoAction.wysiwygLearningAid({ id:target?.element?.id, data:updated }));
        dispatch( courseEditAction.toggleDrawer( { open: false, target: target, itemInfo: curItem, panel:2, drawerWidth: 0 } ) );
    }catch(e){}
    }


   /**
    * The hover target is different because we are not displaying this element's.
    */
    if(!curItem){
        return null;
    }
    
    return (
        <div className='toolbar-container'>
            <div className='toolbar-main'>
                <div className='row tool-row'>
                    <div className='col-md-4 col-sm-12 col-12 id-name'>
                        <div className='toolbar-comp-name'>
                            <p className='toolbar-comp-title'>{ PlayerConstants?.LRN_RE_NAME[target?.cmp?.name] || target?.cmp?.name }</p>
                            <p style={{cursor:"pointer"}} 
                            className='toolbar-comp-id' 
                            title={'Copy Id to clipboard'} 
                            onClick={ () =>copyToClipboard() }>ID No: <span className='comp-id-text'>{getLearningAidId()}</span></p>
                        </div>
                    </div>
                    <div className='col-md-8 col-sm-12 col-12 status-settings'>
                        { isAidEditEnabled ? <div className='toolbar-status'>

                        {ACLHelper.isFeatureEnabled(1,2) && PlayerConstants?.QUICK_AC_SAVE?.includes(target?.cmp?.name) &&
                            <div className='save-btn'>
                                <Button className='submit-btn'  onClick={(e)=> handleSubmit(e) }>Save </Button> 
                            </div>}
                            <div className='toolbar-dropdown'>
                                <label htmlFor="status">Status </label>
                                <select id="status" onChange={ onStatusChange } value={ Number( currentTarget?.data?.status ) }  >
                                    {/* { Object.values(PlayerConstants.STATUS_LRN_AIDS).map( ( el, index ) => <option value={ el.id } key={ index } >{ el.status }</option> ) */}
                                   {getMappedStatus()?.map( ( el, index ) => <option value={ el.id } key={ index } >{ el.status }</option> ) }
                                </select>
                            </div>

                            {PlayerConstants?.TEMPLATE_STYLES?.includes(target?.cmp?.name)&& curItem?.props &&<div className='toolbar-dropdown'>
                                <label htmlFor="templates">Templates </label>
                                <select id="predefined_template" className="predefined_template" onChange={ onTemplateChange } value={selectedTemp[target?.element?.id] || String(curItem?.props?.styleObj?.id) ||''}  >
                                     <option value={'undefined'} disabled selected>Select Template</option>
                                   {Object.keys(PredefinedTemplate[target?.cmp?.name]).map( ( el, index ) => <option value={ el } key={ index } >{ el }</option> ) }
                                </select>
                            </div>}
                            
                            <div className='settings-panel'>
                           
                                <div className='comment-icon icons' onClick={ () => openDrawer( 1, 25 ) }>
                                    <span className='action-btn comment'><CommentIcon /></span>
                                    <p className='label-text'>Comments</p>
                                </div>
                                
                                {ACLHelper.isFeatureEnabled(1,3) && <div className='delete-icon icons' onClick={ () => removeLearningAid( ) }>
                                    <span className='action-btn delete'><DeleteIcon /></span>
                                    <p className='label-text'>Delete</p>
                                </div>}
                                
                                {ACLHelper.isFeatureEnabled(1,2) && <div className='edit-icon icons' onClick={ () => openDrawer(2, 25 ) }>
                                    <span className='action-btn edit'><EditIcon /></span>
                                    <p className='label-text'>Edit</p>
                                </div>}
                                
                            </div>
                        </div> : <div className='toolbar-status'>
                                <div className='toolbar-dropdown'>
                                    <label htmlFor="status">Status </label>
                                    <select id="status" onChange={ onStatusChange } value={ Number( currentTarget?.data?.status ) }  >
                                        {/* { Object.values(PlayerConstants.STATUS_LRN_AIDS).map( ( el, index ) => <option value={ el.id } key={ index } >{ el.status }</option> ) */}
                                    {getMappedStatus()?.map( ( el, index ) => <option value={ el.id } key={ index } >{ el.status }</option> ) }
                                    </select>
                                </div>
                                <div className='settings-panel'>
                           
                                    <div className='comment-icon icons' onClick={ () => openDrawer( 1, 25 ) }>
                                        <span className='action-btn comment'><CommentIcon /></span>
                                        <p className='label-text'>Comments</p>
                                    </div>
                                 </div>
                           <div className='clone-label-btn d-flex align-items-center'>
                                <FormControlLabel className='clone-switch-btn'control={ <Switch checked={ curItem?.isSwitchOn === true ? true:false } onChange={(e)=>handleChange(e,curItem) } /> } label={ 'Customize' }/> 
                                <Button className='clone-save-btn' disabled={ !(curItem?.isSwitchOn === true) } onClick={ saveClone }>Save</Button>
                           </div>
                        </div>
                        }
                    </div>
                </div>
            </div>
            <div className='warning-popup'>

                <div className='danger-popup'>
                <CustomSweetAlert warning
                        showCancel
                        show={ isDeleteConfirm }
                        confirmBtnText="Delete"
                        confirmBtnBsStyle="danger"
                        title="Are you sure you want to delete this Learning Aid ?"
                        text="Are you sure you want to delete this Learning Aid ?"
                        onConfirm={ () => deleteConfirmation() }
                        onCancel={ () => onDeleteCancel() }/>
                </div>
            
                <CustomSweetAlert
                    warning
                    show={ isTemplate }
                    confirmBtnText="Apply"
                    confirmBtnBsStyle="warning"
                    title={`${selectedTemp[target?.element?.id]} template to this learning element, any style modifications made will be superseded by the template's style settings.`}
                    text="template to this learning element, any style modifications made will be superseded by the template's style settings."
                    onConfirm={ () => setIsTemplate(false) }
                    onCancel={ () => setIsTemplate(false) }
                    />

                <CustomSweetAlert
                   warning
                    showCancel
                    show={ minAidAlert }
                    confirmBtnBsStyle="warning"
                    title="There must be at least one Learning Aid for the Topic"
                    onConfirm={ () => closeAlertBox() }
                    onCancel={ () => closeAlertBox() }/>
            </div>
        </div>
    )
}

QuickAccessBar.propTypes = {
    target: PropTypes.object,
}


export default QuickAccessBar
