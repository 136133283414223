import React, { useCallback, useEffect } from "react";
import './App.scss';
import Layout from './container/Layout/Layout';
import Home from "./container/Home/Home";
// import SplashScreen from "./container/SplashScreen/SplashScreen";
import { HashRouter as Router, Route, Redirect} from "react-router-dom";
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import Login from "./container/Login/Login";
import Profile from "./container/Profile/Profile";
import DashBoard from "./container/DashBoard/DashBoard";
import Drawer from "./components/ui/Drawer/Drawer";
import { ToastProvider } from "react-toast-notifications";
import { useSelector } from "react-redux";
import InactivityRedirector from "./utils/InactivityRedirector";
import PlayerConstants from "./utils/PlayerConstants";
import DataHelper from "./utils/DataHelper";
import BuildHelper from "./utils/BuildHelper";
import t from "./translation/useTranslate";
import { initializeI18n } from "./translation/i18n";
import Loader from "./components/ui/Loader/Loader";

const routes = [
  { path:'/', name: 'Home', Component: Home },
  { path:'/course', name: 'Course', Component: Layout },
  { path:'/login', name: 'Login', Component: Login },
  { path:'/profile', name: 'Profile', Component: Profile },
  { path:'/dashboard', name: 'DashBoard', Component: DashBoard }
];

const App = () => {
  // Track course information
  const {globalStyle,courseInfo, courseTracking} = useSelector(state => state);
  const [isTranslationReady, setIsTranslationReady] = React.useState(false);

/**
 * Translation Flow with i18n Library (20/08/24)
 * 
 * 1. First, we make an auth call storing `courseTracking?.crsProgress`.
 * 2. there based on `playerConfigJson`, we display a language selection popup.
 *    - The popup appears only if there is more than one language in the list.
 *    - This popup is shown on the Home page.
 * 3. The selected language is stored in `sco(lan)` and also in local storage as `config_id_LN`.
 * 4. If no language list is available, we use English as the fallback language.
 */
  useEffect(() => {
   if(!t('START') && !courseTracking.isLoading && !courseInfo?.isLoading){
    let language=courseTracking?.courseTracking?.lan||courseTracking?.crsProgress?.sco?.lan
    initializeI18n(language).then(() => {
      setIsTranslationReady(true);
      console.log('i18n initialized successfully');
    }).catch(error => {
      console.error('Error initializing i18n:', error);
    });
   }
  }, [courseInfo?.flatten]); // Add trackInfo to the dependency array

  const globalStyles=useCallback(()=>{
    const fontFamily=globalStyle?.font?.split('|')||"";
    let language=courseTracking?.courseTracking?.lan||courseTracking?.crsProgress?.sco?.lan;
    const isRtl=(PlayerConstants?.RTL_LANGUAGES?.includes(language || 'en_US') && BuildHelper.isLearner());
    const styles={
      '--direction':isRtl ? "rtl" : "ltr",
      '--global-font-family':fontFamily[0],
      '--global-font-family-medium':fontFamily[1]||fontFamily[0],
      '--global-font-size': `${DataHelper?.getGlobalFontSize(globalStyle?.comspace?.gfs)|| 24}px`,
      ...(PlayerConstants?.palette[globalStyle?.theme]?.styles || {}),
    }
   let className=`${globalStyle?.isLinear ? "crs-linear-container linear-header ": 'tree-header'} ${globalStyle?.comspace?.isfs && "gfs"} ${globalStyle?.theme} ${isRtl && "right-to-left"}`
   return {styles:styles, class:className}||{}
  },[courseTracking?.courseTracking,globalStyle])

 /**
  * This loading state ensures the page is loaded only after the translation is ready
  */
  if (!isTranslationReady && courseTracking?.crsProgress?.sco?.lan) {
    return <Loader/>;  
  }

  const renderContent = () => (
    <Router>
      {window?.ce?.platform_scorm?.topicplayer === false || window?.ce?.platform_scorm?.topicplayer === undefined
        ? <AnimatedSwitch store={globalStyle} trackInfo={courseTracking} />
        : <TopicPlayer />}
      <Drawer />
    </Router>
  );


  return (
    <div  className={globalStyles()?.class} style={{...globalStyles()?.styles} } >
   {BuildHelper.isLearner() ? 
   <InactivityRedirector timeout={PlayerConstants?.REDIRECT?.TIME} redirectPath={PlayerConstants?.REDIRECT?.HREF} >
       {renderContent()}
    </InactivityRedirector> :  renderContent() }
    </div>
  );
}

const TopicPlayer = () => (
  <ToastProvider placement="bottom-left" autoDismiss={ true }>
    <TransitionGroup>
      <Route exact path={ process?.env?.PUBLIC_URL + '/' } component={ Layout } />
    </TransitionGroup>
  </ToastProvider>
);

const AnimatedSwitch = ({trackInfo}) => {
// const lnLength=BuildHelper?.languageList()?.languages?.length||false;
// const shouldRedirectToHome =((trackInfo?.courseTracking && !lnLength) || !BuildHelper.isLearner() || BuildHelper.defaultLanguage());
 return <ToastProvider placement="bottom-left" autoDismiss={ true }>
    <TransitionGroup>
      { routes.map( ({ path, Component }) => (
        <Route key={ path } exact path={ path } >
          { ({ match }) => (
            <CSSTransition
              in={ match != null }
              timeout={ 0 }
              classNames="page"
              unmountOnExit
            >
              <div className="page">
                <Component />
              </div>
            </CSSTransition>
          ) }
        </Route>
      ) ) }
    </TransitionGroup>
  </ToastProvider>
};

export default App;
