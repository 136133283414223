import ContentTracking from "../../core/progress/ContentTracking";
import CourseTracking from "../../core/progress/CourseTracking";
import TopicTracking from "../../core/progress/TopicTracking";
import BuildHelper from "../../utils/BuildHelper";
import DataHelper from "../../utils/DataHelper";
import PlayerConstants from "../../utils/PlayerConstants";
import * as types from "../reducers/types";

const initialState = {
  flatten: {},
  courseTracking: null,
  isLoading: false,
  lrs: null,
  hasLanguage:false,
  scorm: {},
  location_id:0,
// We are tracking the very first start
 isInitial : true, // or false, depending on isSCOAvailable
   crsProgress:null

};
const userAgent = navigator?.userAgent?.toLowerCase()||[];

const courseTrackingReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CRS_PROGRESS_REQ:
      return{
        ...state,
        isLoading:true
      }
    case types.CRS_PROGRESS_RES:
      let course = (action?.payload ?? [])[0] ?? {};
      if(course?.player_config_json)course.player_config_json=JSON.parse(course?.player_config_json);
      if(!BuildHelper.isOnScorm()){
        if(course?.sco)course.sco = JSON.parse(course?.sco);
      }else{
        window.ce.platform_scorm.lnlist= course?.player_config_json?.i18n
      }
      const isOpen = course?.player_config_json?.i18n?.languages?.length > 1 && !course?.sco?.lan && !course?.lan;
      return{
        ...state,
        crsProgress:{...course},
        hasLanguage:isOpen,
        isLoading:false
      }
      case types.HAS_LANGUAGE_VIEW:
        return{
          ...state,
          hasLanguage:!state?.hasLanguage,
        }
    case types.COURSE_TRACKING_INIT:
      let crsProgressData=action.payload.update ? {} :  state?.crsProgress;
      state.flatten={};
      DataHelper.listIterator(
        action.payload.data.coursedata,
        0,
        "",
        action.payload.topicdata,
        state.flatten,
        action?.payload?.data?.CfgClientComm
      );
      let progressed = null;
      /**
       * Tracking the location ID
       * that we use in the statements.
      */
      setLocationId(state,crsProgressData);

      console.log(crsProgressData, "=========isSCOAvailable============", isSCOAvailable(crsProgressData));
      /**
       * If platform and not Scorm
       * Assumption : LRS is available in Platform
       */
      const isSCO = isSCOAvailable(crsProgressData);
      if (isSCO && !BuildHelper.isOnScorm()) {
        if(!userAgent?.includes('firefox'))  state.lrs.getAppState();
        progressed = setProgressProgressData(state.flatten, crsProgressData);
      }
      /**
       * If the package is Scorm
       */
      else if (BuildHelper.isOnScorm()) {
        progressed = DataHelper.initialiseProgressData(state.flatten);
        let scormdata = state.scorm.encryptProgress(progressed.t);
        let ll = state.scorm.getLessonLocation();
        state.scorm.scormobj.progress = scormdata.toString()
        console.log("Get APP SCORM DATA", scormdata);

        /**
         * Check wheather SCORM is present
         * Or data in scorm is accurate
         */
        try {
          let descormdata = action?.payload?.update ? {} : state?.crsProgress?.sco;
          /**
           * Scorm data decrypting error
           * If nothing found fall back into
           * initial data
           */
          // console.log(descormdata.length ,":::::::::::", Object.keys(progressed.f).length)
          if (descormdata !== undefined && descormdata.topic.length === Object.keys(progressed.f).length) {
            progressed = setProgressProgressDataScorm(state.flatten, descormdata, ll);

            //  console.log(sprogressed)
            //  console.log(progressed)
          }

        }
        catch (e) {
          console.log('Exception in Scorm API / Exception in Scorm data :: ', e)
        }





      }
      else  {
        progressed = DataHelper.initialiseProgressData(state.flatten);
        if(!userAgent?.includes('firefox')) state?.lrs?.trackActivity("launch", 1, null);
      }
      return {
         ...state, 
        courseTracking: progressed.t,
        flatten:DataHelper.deepClone(state?.flatten),
        isInitial:!isSCO,
        isLoading:false
       };
      break;

    case types.COURSE_COMPLETION_NOTIFIED:
      if(!userAgent?.includes('firefox')) state?.lrs?.trackActivity("completed", -1, null);
      return { ...state };
      break;

    case types.COURSE_TRACKING_SAVED:
      if(!userAgent?.includes('firefox')) state.lrs.saveAppState({ data: state.courseTracking });
      return { ...state };
      break;
      case types.EXIT_PRE_TEST:
        return { ...state, isInitial:false };
    case types.COURSE_TRACKING_SAVE:
      let crsTracking = action?.payload?.courseTracking||action?.payload||{};
      if (BuildHelper.isOnScorm()) {
        try{
          let encrypted = state.scorm.encryptProgress(crsTracking)
          if(crsTracking?.scorm?.ll){
            let ll = crsTracking?.scorm?.ll.split('^');
            state.scorm.setTotalTime(ll[0] || 1000);
            state.scorm.setPercentage(ll[ll?.length-1] || 1);
            const hasCompleted=(crsTracking?.scorm?.sp && /^[c]+$/.test(crsTracking.scorm.sp)) ||  crsTracking?.scorm?.ll.includes('^100')
            state.scorm.setLessonStatus(hasCompleted ?  1 : 3);
            if(hasCompleted){
              state.scorm?.setScoreRaw(100);
              state.scorm?.setScoreMax(100);
              state.scorm?.setScoreMin(80);
          } 
          }
          else{
            crsTracking.scorm.ll = state.scorm.getRawLessonLocation();
          }
          if(crsTracking?.scorm?.sp)
          crsTracking.scorm = { sd: encrypted, ll:crsTracking?.scorm?.ll, sp: crsTracking?.scorm?.sp  }
          state.scorm.setScormData(encrypted)
          state.scorm.setLastAccessedTopic(crsTracking?.visited?.last);
          state.scorm.setSuspendData(crsTracking.scorm.sd)
        }catch(e){}
      }
      if (!BuildHelper.isOnScorm()) {
        try{
          if(crsTracking?.scorm)
            crsTracking.scorm.ls = Number(crsTracking?.scorm?.sp && /^[c]+$/.test(crsTracking.scorm.sp) ?  1 : null)
          if (window.parent) {
            let ps = {ll :crsTracking?.scorm?.ll,ls:(Number(crsTracking?.scorm?.ls)||null),sp:crsTracking?.scorm?.sp,uid:window?.ce?.platform_scorm?.userId||"",cid:Number(window?.ce?.platform_scorm?.cid||1)  }
            window.parent.postMessage({ "platform_scorm": ps}, "*");
          }
        }
        catch(e){
          console.log(e)
        }
         if(Number(crsTracking?.scorm?.ls) === 0){
          crsTracking.scorm.ls = null;
         }
      }

      return { ...state, courseTracking: crsTracking };
      break;

    case types.LRS_INITIALISE:
      return { ...state, lrs: action.payload };
      break;
    case types.SHORTCUT_COMPLETION:
      let prObj = shortcutCompletion(action.payload);
      return { ...state, courseTracking: prObj.t };
      break;
    case types.SCORM_INITIALISE:
      return { ...state, scorm: action.payload };
    default:
      return state;
  }
};
// const initialiseProgressData = (flatten) => {
//   let progressFlattened = {};
//   let statusList = [];
//   var courseTracking = new CourseTracking();
//   for (var i in flatten) {
//     let tptracking = new TopicTracking(i, flatten[i]?.id);
//     if (flatten[i].lrncontent) {
//       // if (flatten[i].lrncontent) {
//       let comp = flatten[i].lrncontent;
//       let learnaids = flatten[i].lrncontent;
//       /** Getting Component PermanentId from
//        * PlayerConstants.getComponentByName(comp[j].name).id
//        * comp[j].id is a configured Id ==> Given Id while creating template string
//        * ex.0:1
//        * COMPONENTS_LIST[0]:flatten[i].lrncontent[1]
//        */
//       statusList = [];
//       for (var k in learnaids) {
//         let cid = PlayerConstants.getComponentByName(learnaids[k]?.name)?.id + ":" + learnaids[k]?.compid;
//         let ctr = new ContentTracking(cid, 0, {});
//         statusList.push(ctr);
//         tptracking.content.push(ctr);
//       }
//       for (var j in comp) {
//         let cid =
//           PlayerConstants.getComponentByName(comp[j]?.name)?.id +
//           ":" +
//           comp[j]?.id;
//         let ctr = new ContentTracking(cid, 0, {});

//         // statusList.push(ctr);
//         // tptracking.content.push(ctr);
//       }
//       courseTracking.topic.push(tptracking);
//     }

//     progressFlattened[i] = {
//       i: flatten[i]?.id,
//       cmp: statusList,
//       s: -1,
//       cp: 0,
//       ts: Date.now(),
//     };
//   }
//   /**
//    * changing the first node active
//    */
//   let tpKeys = Object.keys(progressFlattened);
//   let firstChild = tpKeys.find((c) => {
//     return progressFlattened[c].cmp.length > 0;
//   });
//   progressFlattened[firstChild].s = 0;
//   // console.log(progressFlattened[firstChild],progressFlattened)
//   return { f: progressFlattened, t: courseTracking };
// };

/**
 * Setting progress data from SCORM
 * @param {*} flatten 
 * @param {*} progress 
 * @returns 
 */
const setProgressProgressDataScorm = (flatten, progress, ll) => {
  if (progress === undefined) {
    return
  }
  let crstTrack = new CourseTracking();
  (progress?.topic || progress).forEach(item => {
    let tptracking = new TopicTracking(
      item.flatId,
      flatten[item.flatId]?.id,
      item.percent,
      item.status,
      item.timestamp
    );

    item.content.forEach((c) => {
      tptracking.content.push(new ContentTracking(c?.id, c.status, c.state));
    });
    crstTrack.topic.push(tptracking);
    /** Setting back the values from API */
    flatten[item.flatId].p = item?.percent;
    flatten[item.flatId].status = item?.status;

  })
  /** Setting status for parentNode */
  Object.keys(flatten).forEach((item, index) => {
    if (flatten[item].children) {
      flatten[item].status = setTopicStatus(crstTrack, flatten[item], index)
    }
  });
  crstTrack.scorm = {ll:ll?.ttime + '^' + ll?.topic + '^' + stringifyDates(ll?.cdates) + '^' + ll?.percentage}
  crstTrack.visited = {last : ll?.topic}
  crstTrack.lan=progress?.lan
  // console.log(flatten, crstTrack)
  let courseNode = flatten[Object.keys(flatten)[0]];
  courseNodeCompletion(courseNode);
  /** setting back the sco object */


  return { f: flatten, t: crstTrack };
}
const stringifyDates = (dlist) => {
  let d, date, month, year;
  let dstr = "";
  let df = "";
  for (let i = 0; i < dlist.length; i++) {

      d = dlist[i];
      date = d.getDate();
      month = d.getMonth() + 1;
      year = d.getFullYear();

      if (month < 10) {
          month = '0' + month;
      }
      if (date < 10) {
          date = '0' + date;
      }

      df = date + '' + month + '' + String(year).substring(2);
      dstr += df;

  }
  console.log("dstr",dstr)
  return dstr;
}

const setLocationId=(location,progress)=>{
  try{
    let course = progress
    location.location_id=course?.location_id ||"0"
  }catch(e){}
}

const setProgressProgressData = (flatten, progress) => {
  /**
   * finding the course from params
   * getting the sco and passing it to content area
   *
   *
   */
  let progressFlattened = {};

  let course = progress;

  // console.debug("course", course);
  let crstTrack = new CourseTracking();
  try {
    let scoValue =course?.sco||{};
    scoValue?.topic?.forEach((item) => {
      let tptracking = new TopicTracking(
        item?.flatId,
        item?.topicId,
        item?.percent,
        item?.status,
        item?.timestamp
      );
      item?.content?.forEach((c) => {
        tptracking?.content?.push(new ContentTracking(c?.id, c?.status, c?.state));
      });
      crstTrack?.topic?.push(tptracking);
      /** Setting back the values from API */
      flatten[item?.flatId].p = item?.percent;
      flatten[item?.flatId].status = item?.status;
    });
    /** Setting status for parentNode */
    Object.keys(flatten)?.forEach((item, index) => {
      if (flatten[item]?.children) {
        flatten[item].status = setTopicStatus(crstTrack, flatten[item], index)
      }
    });



    /** setting back the sco object */
    // if (Object.keys(scoValue?.visited)?.length > 0) {
      crstTrack.scorm = { ...scoValue?.scorm }
      crstTrack.visited = { ...scoValue?.visited }
      crstTrack.completed_mail =course?.completed_mail_sent||1;
      crstTrack.lan=scoValue?.lan||BuildHelper?.defaultLanguage()||null
      if(scoValue?.lan)localStorage.setItem(`${BuildHelper.configId()}_LN`,JSON.stringify(scoValue?.lan||'en_US'));
    // };

    /** Asssuming firsnode in flatten will be course title node */
    let courseNode = flatten[Object.keys(flatten)[0]];
    courseNodeCompletion(courseNode);

  } catch (e) {
    console.log(e);
  }
  // let scoValue = JSON.parse(progress.data.courses[0].sco)

  return { f: flatten, t: crstTrack };
};

/**
 * item is a flatten item here
 * @param {*} item 
 */
const courseNodeCompletion = (item) => {
  if (item.children) {
    const completed = item.children.filter((el) => {
      return Number(el.p) === 100
    });

    if (completed.length === item.children.length) {
      item.p = 100;
      item.status = 1;
    }
  }
}

/**
 * 
 * @param {*} crstTrack 
 * @param {*} node 
 * @returns 
 */

const setTopicStatus = (crstTrack, node, index) => {
  let tstatus = node.status;
  let isNotVisited = node.children.find(child => { return child.status === -1 })
  let isVisited = node.children.find(child => { return child.status === 0 })
  let completed = node.children.filter(child => { return child.status === 1 })
  // console.debug("isVisited",isVisited,"isNotVisited",isNotVisited,node.title)
  /**
   * Something is visited
   * visited and non visited combination  set status to visited 
   * All visited status completed
   */
  // console.log("node",node.nodeId,node.title,node.lrncontent);
  if ((isVisited && !isNotVisited) || (isVisited && isNotVisited)) {
    tstatus = 0;
  }
  else if (completed.length === node.children.length) {
    let parntStatus = 1;
    // if ( node.lrncontent && node.lrncontent.length > 0 ) {
    if (node.lrncontent && DataHelper.checkEmptyTopic(node.lrncontent)) {
      let progress = crstTrack.topic.find(parnt => { return parnt.flatId === node.nodeId })
      let parentProgres = progress.content.filter(pval => { return pval.status === 1 })
      parntStatus = (parentProgres.length === progress.content.length ? 1 : 0)
    }
    /** Some scenario top node percentage is not setting it 
     * so mutated here ,TODO
     */
    if (parntStatus) {
      node.p = 100
      node.status =1
      if (BuildHelper.isOnScorm()){
        crstTrack.topic[index].percent = 100;
        crstTrack.topic[index].status = 1;
      }
    }
    tstatus = parntStatus;
  }

  return tstatus
}

/**
 * 
 * @param {*} res = is the SCO JSON from API
 * @returns true or false
 */
const isSCOAvailable = (res) => {
  let isSco = false;
  try {

    if (Object.keys(res).length > 0) {
      // let data = res;
      let course =res;
      // let course = data.courses.filter((el) => {
      //  return Number(el.course_Id) === Number(window.ce.platform_scorm.cid);
      // });
      if (course.length === 0) {
        isSco = false
      } else {
        let sco = course?.sco||{};
        isSco = Object.keys(sco)?.length !== 0 ? true : false;
        if(sco?.topic)  isSco =(sco?.topic?.length !== 0) ? true : false;
      }
    } else {
      isSco = false;
    }

  } catch (e) {
    console.log("Error in getting Scorm values.  Check isSCOAvailable");
  }
  return isSco;



};

/**
 * 
 * SHORTCUT  key press to complete the course
 * @param {*} flatten 
 * @returns 
 */
const shortcutCompletion = (flatten) => {
  let statusList = [];
  if (flatten) {
    var courseTracking = new CourseTracking();
    for (var i in flatten) {

      flatten[i].p = 100
      flatten[i].status = 1

      let tptracking = new TopicTracking(i, flatten[i]?.id);
      if (flatten[i].lrncontent) {
        let comp = flatten[i].lrncontent;

        statusList = [];
        for (var j in comp) {
          let cid =
            PlayerConstants.getComponentByName(comp[j]?.name)?.id +
            ":" +
            comp[j]?.id || comp[j]?.compid;
          let ctr = new ContentTracking(cid, 1, {});

          statusList.push(ctr);

          tptracking.percent = 100
          tptracking.status = 1

          tptracking.content.push(ctr);
        }
        courseTracking.topic.push(tptracking);
      }

    }
    // console.debug( "courseTracking", courseTracking )
  }
  return { t: courseTracking }
}


export default courseTrackingReducer;

