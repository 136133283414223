import React from "react";
import PropTypes from "prop-types";
import styled, { keyframes } from "styled-components";
import CloseIcon from "@mui/icons-material/Close";
// import shadows from "@material-ui/core/styles/shadows";

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  display: ${(props) => (props?.isOpen ? "flex" : "none")};
  align-items: center;
  justify-content: center;
  z-index: 9999999999;
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(0.9);
  }
`;

const ModalContainer = styled.div`
  box-shadow: ${(props) => props?.shadow || "0px 4px 8px rgba(0, 0, 0, 0.2) "};
  border-radius: 4px;
  overflow: hidden;
  width: ${(props) => props?.width || "400px"};
  height: ${(props) => props?.height || "400px"};
  background: ${(props) => props?.bgColor || "#fff"}!important;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  @media (max-width: 901px) {
    width: 90%;
    height: 90%;
  }
  @media (max-width: 601px) {
    width: 90%;
    max-height: 60%;
  }
  animation: ${(props) => (props.isOpen ? fadeIn : fadeOut)} 0.3s ease;
  z-index: 99999999999;
`;

const Header = styled.div`
  background-color: transparent;
  color: white;
  padding: 16px;
  text-align: left;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  color: #000;
  cursor: pointer;
  font-size: 2.5rem;
  font-weight: bold;
  position: absolute;
  top: 0px;
  right: 18px;
  svg {
    font-size: 2.5rem;
    background: #fff;
    border-radius: 50%;
    z-index: 9;
  }
`;

const Content = styled.div`
  padding: 0 16px;
  flex-grow: 1;
  overflow-y:auto;
  margin-top:6% !important;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 16px;
`;

const ActionButton = styled.button`
  background-color: #fff;
  color: black;
  padding: 8px 16px;
  margin-left: 8px;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  transition: background-color 0.3s, color 0.3s;
  border: 1px solid var(--primary-color-1);
  min-width: 90px;
  font-size:14px;
  &:hover {
    background-color:  ${(props) => (props?.bgColor ? props?.bgColor :  "#13984b")};
    color:${(props) => (props.color ? props.color :  "#fff")};
    border: 1px solid ${(props) => (props?.bgColor ? props?.bgColor :  "#13984b")};
  }
`;

const CommonModal = ({
  isOpen,
  onClose,
  title,
  content,
  buttons,
  width,
  bgColor,
  outsideClick,
  shadow,
  height,
  className
}) => {
  const handleContainerClick = (event) => {
    if(outsideClick) onClose(event);
    event.stopPropagation();
  };

  return (
    <Overlay isOpen={isOpen} className={`common-model-cnt ${className}`} onClick={handleContainerClick}>
      <ModalContainer
        isOpen={Boolean(isOpen)}
        width={width ?? "200px"}
        height={height ?? "100px"}
        bgColor={bgColor ?? ""}
        shadow={shadow ?? ""}
        // onClick={handleContainerClick}
        className={`${CloseButton ? "box-shadow-none" : ""}  ${className}_inner`}
        style={CloseButton ? { boxShadow: "none !important" } :""}
      >
        <Header className="header">
          <h2>{title}</h2>
            <CloseButton
              className="image-gallery-display-btn"
              onClick={onClose||handleContainerClick}
             >
              <CloseIcon />
            </CloseButton>
        </Header>
        <Content>{content||<></>}</Content>
        <ButtonContainer>
          {buttons?.length > 0 &&
            buttons?.map((button, index) => (
              <ActionButton
               className={`${button?.class} ${!button?.isDisable &&  "disabled-btn"}`}
                key={index+1||1}
                onClick={(button?.func && button?.func) || (() => {})}
                bgColor={button?.bgColor || ""}
                color={button?.color || ""}
                disabled={button?.isDisable || false}
              >
                {button?.text || ""}
              </ActionButton>
            ))}
        </ButtonContainer>
      </ModalContainer>
    </Overlay>
  );
};

CommonModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  content: PropTypes.node,
  // buttons: PropTypes?.arrayOf(
  //   PropTypes.shape({
  //     text: PropTypes.string.isRequired,
  //     func: PropTypes.func.isRequired,
  //     bgColor: PropTypes.string,
  //     class: PropTypes.string,
  //   })
  // ),
  width: PropTypes.string,
};

export default CommonModal;
