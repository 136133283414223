import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import TabsView from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Video from '../Video/Video'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import Tooltip from '@material-ui/core/Tooltip';
import './tabs.scss'
import ContentTracking from '../../../core/progress/ContentTracking';
import PlayerConstants from '../../../utils/PlayerConstants';
import Instruction from '../../ui/Instruction/Instruction';
import DataHelper from '../../../utils/DataHelper';
import ImageTag from '../../ui/ImageTag';

const TabContainer = (props) => {
    return (
        <div>
            {props.index === props.activeTab && <Typography component="div">
                {props.children}
            </Typography>}
        </div>
    );
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
};

const styles = theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
});

/**
 * 
 * @param {*} items 
 * @returns an Array of images and description content on each object
 */

/**
 * Tabs will have different content on each sections that can be switched between them
*/
const Tabs = (props) => {
    const { items,track,handler, instructions } = props

    const [activeTab, setTabActive] = useState(0);
    const [viewedList,setViewedList] = useState([]);
    const [status, setStatus] = useState({completed: false, text: PlayerConstants.COMPONENT_CONSTANTS.STATUS_INCOMPLETE})
    const [instruction, setInstruct] = useState(instructions)
    /** default active tab is setting  
     * into progress if nothing is clicked */
    useEffect(()=>{
        viewedList[0] = activeTab;
        updateProgress(activeTab)
    },[])


    const UPDATE_STATUS = PlayerConstants.COMPONENT_CONSTANTS.UPDATE_STATUS
    const SAVE_PROGRESS_STATUS = PlayerConstants.COMPONENT_CONSTANTS.SAVE_PROGRESS_STATUS

    const handleChange = (event, index) => {
        setTabActive(index);

      /** progress update start */
      if ( viewedList.indexOf( index ) === -1 ) {
        viewedList.push( index );
        updateProgress(index)
      }
      setViewedList( [ ...viewedList ]);
      /** progress update end */
    };


     /**
     * Manage the component update 
     * progress logic in this method
     * 
     * Update the view status when ever the user interacts
     * Update the progess status ONLY ONCE, when status completes
     * 
     */
  const updateProgress = (currentItem) => {

    /**
     * View status, will update while navigating to next topic
     */
    track.state = { ...track.state, c: currentItem }
    /**
     * Progress updated as and when its completed
     */

    if (!track.status) {
      track.status = (viewedList.length === items.length) ? 1 : 0;
      if (track.status) {
        setInstruct({
            ...instruction,
            text: `You have completed ${props.cename}, you can proceed to next section`,
            className: 'completed'
        })
        setStatus({completed: true, text:PlayerConstants.COMPONENT_CONSTANTS.STATUS_COMPLETE})
        handler({ type: SAVE_PROGRESS_STATUS, id: track.id, name: props.cename })
      }
    }
    /**
     * If anything to be intimated always
     * call this
     */
    handler({ type: UPDATE_STATUS, id: track.id, name: props.cename })

  }

    return (
        <>
        <Instruction isInstruction={instruction?.enabled} completed={(track.status === 1 || status.completed)} title={(track.status === 1 || status.completed) ? PlayerConstants.COMPONENT_CONSTANTS.STATUS_COMPLETE : PlayerConstants.COMPONENT_CONSTANTS.STATUS_INCOMPLETE} classText={`${(track.status === 1 || status.completed) && PlayerConstants.COMPONENT_CONSTANTS.COMPLETED_CLASS}`} text={(track.status === 1 || status.completed) ? PlayerConstants.COMPONENT_CONSTANTS.INSTRUCTIONS_PASSED : instruction?.text}/>
        <div className="tab-list">
            <AppBar position="static">
                <TabsView value={activeTab} >
                    {items && items.length > 0 && items.map((elem, index) => {
                        return (
                            <Tab style={{ width: (100 / items.length) + '%' }} key={elem.id} label={elem.title} value={index} onClick={(e) => handleChange(e, index)}></Tab>
                        )
                    })}
                </TabsView>
            </AppBar>
            {items && items.length > 0 && items.map((el, index) => {
                return <TabContainer key={el.id} index={index} activeTab={activeTab}>
                    {index === activeTab && <div>
                        <p className="description" dangerouslySetInnerHTML={{__html: el.description}}></p>
                       {(el.imageUrl && el.imageUrl !=="") && <div className='image'>
                            {getTabData(el)}
                        </div>}
                    </div>}
                </TabContainer>
            })}
        </div>
        </>
    );
}

 /**
 *  Look for url its contain el.imageUrl or VideoUrl 
 * either video or imageurl has be there
 * keep el.imageUrl as '' to renderVideo 
 * keep el.videoUrl as '' to renderImage
*/
const getTabData = (el)=>{
    try{
        if(el.imageUrl && el.imageUrl !=="")
            return renderImage(el?.imageUrl,el?.igColor )
        if(el.video && Object.keys(el.video).length > 0)
            return renderVideo(el.video)
    }
    catch(e){
        console.log("---error in tab---",e)
    }
}

const getResourceUrl = (path)=>{
    return DataHelper.getResourcePath(0,path)
}

const getAbsUrl = (src)=>{
    try{
        if(src)
        return src.startsWith('http') ? src :getResourceUrl(src)
    }catch(e){
        console.log(e)
    }
}

const renderImage = (url,color)=>{
    return  <ImageTag
    img={url || "*"}
    bgColor={color || "rgba(0, 0, 0, 0)"}
    />
    // <img alt="tab-item-image" 
    // src={getAbsUrl(url)}
    // />
}

const renderVideo =(props)=>{
    return <div className="tab-video">
        <Video src={getResourceUrl(props?.src)} 
        poster={getResourceUrl(props?.poster)} 
        cuepoints={props.cuepoints} colors={props.colors} readable={''}></Video>
    </div>
}

Tabs.defaultProps = {
    instructions: {
        text: 'Please click on each section to complete this interactivity',
        enabled: false
    }
}

Tabs.propTypes = {
    /** Array of images and description content on each object */
    items: PropTypes.array.isRequired,
    /** Tracking the component progress */
    track: PropTypes.instanceOf(ContentTracking),
    /** Func description for tracking*/
    handler:PropTypes.func
};

export default withStyles(styles)(Tabs);