import React from 'react';
import PropTypes from 'prop-types';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import './customCheckbox.scss'

const CustomCheckbox = ({ label, checked, name, onChange, labelPlacement, className,disable }) => (
    <FormControlLabel
        label={label}
        disable={disable}
        control={<Checkbox checked={checked||false} name={name||""} onChange={onChange} />}
        labelPlacement={labelPlacement||"end"}
        className={`${className||""} ck-checkbox-root`}
    />
);


CustomCheckbox.propTypes = {
    // Label text for the checkbox
    label: PropTypes.string.isRequired,
    // Initial checked state of the checkbox
    checked: PropTypes.bool,
    // Name attribute for the checkbox input
    name: PropTypes.string.isRequired,
    //  Function to handle checkbox change events
    onChange: PropTypes.func.isRequired,
    // Placement of the label relative to the checkbox ('start', 'end', 'top', 'bottom')
    labelPlacement: PropTypes.oneOf(['start', 'end', 'top', 'bottom']),
};

// Default Prop Values
CustomCheckbox.defaultProps = {
    checked: false,
    labelPlacement: 'end', // Default label placement
};

export default CustomCheckbox;
