/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useRef, useState, useCallback } from "react";
import PropTypes from 'prop-types';
import Pickr from '@simonwep/pickr/dist/pickr.es5.min';
import './ColorPickr.scss'
import "@simonwep/pickr/dist/themes/classic.min.css"; 
// import '@simonwep/pickr/dist/themes/monolith.min.css';
import '@simonwep/pickr/dist/themes/nano.min.css';
import FormatColorFillIcon from '@mui/icons-material/FormatColorFill';

/**
 * Converts a hex color code to an RGBA string.
 * @param {string} hex - The hex color code.
 * @returns {string} The RGBA color string.
 */
const hexToRGBA = (hex) => {
    // Remove the hash character if present
    hex = (hex||"#0000").replace(/^#/, '');
    // Parse the hex value into RGB components
    const bigint = parseInt(hex, 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
    // Return the RGBA string
    return `rgba(${r}, ${g}, ${b}, 1)`;
  };

const ColorPick = ({ color, saveEvent, cancelEvent, clearEvent, onChange, btn, type, icon }) => {
    const colorPicker = useRef(null);

 /**
   * Opens the EyeDropper tool and sets the selected color.
   * @param {object} pickr - The Pickr instance.
   * @returns {Promise<string>} The selected color in RGBA format.
   */
    const openEyeDropper = useCallback(async (pickr) => {
        if ('EyeDropper' in window) {
          const eyeDropper = new window.EyeDropper();
          const color = await eyeDropper.open();
          if (color) {
            const rgbaValue = hexToRGBA(color.sRGBHex);
            saveEvent(rgbaValue);
            pickr.setColor(rgbaValue);
             pickr.hide()
            return rgbaValue;
          }
        }
    },[saveEvent]);
      

  /**
   * Converts the color object to the specified format.
   * @param {string} type - The desired color format.
   * @param {object} color - The Pickr color object.
   * @returns {string} The color in the specified format.
   */
    const getColor = (type, color) => {
      try{
        switch (type) {
          case "RGBA":
            return color.toRGBA().toString();
          case "HEXA":
            return color.toHEXA().toString();
          // Add more cases for additional color formats if needed
          default:
            return color.toRGBA().toString();
        }
       }catch(error){
        console.error("Error converting color:", error);
        return color.toRGBA().toString();
       }
      };

  /**
   * Initializes the Pickr instance and sets up event listeners.
   */
    useEffect(() => {
        try {
            if (colorPicker.current) {
                let pickr = new Pickr({
                    el: colorPicker.current,
                    container: "body",
                    theme: "nano", //'nano', 'classic', 'monolith'
                    closeOnScroll: false,
                    appClass: "custom-class",
                    useAsButton: false,
                    padding: 8,
                    inline: false,
                    autoReposition: true,
                    sliders: "h",
                    disabled: false,
                    lockOpacity: false,
                    outputPrecision: 0,
                    comparison: true,
                    default: color || '#rgba(7, 13, 89, 0.5)',
                    swatches: ["#F44336", "#E91E63", "#9C27B0", "#673AB7",'#F44336','#E91E63','#9C27B0','#CDDC39','#FFEB3B','#FFC107'
                    ,'#673AB7','#3F51B5', '#2196F3','#03A9F4','#00BCD4','#009688','#4CAF50','#8BC34A',
                                ],
                    defaultRepresentation: type || "HEX",
                    showAlways: false,
                    closeWithKey: "Escape",
                    position: "bottom-middle",
                    adjustableNumbers: true,
                    
                    components: {
                        palette: true,
                        preview: true,
                        opacity: true,
                        hue: true,
                        interaction: {
                            hex: true,
                            rgba: false,
                            hsla: false,
                            hsva: false,
                            cmyk: false,
                            input: true,
                            cancel: btn?.cancel?.isCancel || false,
                            clear: btn?.clear?.isClear || false,
                            save: btn?.save?.isSave || false,
                            buttons: [
                                {
                                    name: 'eyedropper',
                                    title: 'Eyedropper',
                                    className: 'eyedropper-button',
                                    click: () => openEyeDropper(pickr),
                                }]
                        },
                    },
                    i18n: {
                        "ui:dialog": "color picker dialog",
                        "btn:toggle": "toggle color picker dialog",
                        "btn:swatch": "color swatch",
                        "btn:last-color": "use previous color",
                        "btn:save": btn?.save?.name || 'save',
                        "btn:cancel": btn?.cancel?.name || 'cancel',
                        "btn:clear": btn?.clear?.name || 'clear',
                        "btn:eyedropper":"eyedropper",
                        'aria:btn:cancel':  btn?.cancel?.name === "&#x270E" && "colorize-icon",
                        'aria:btn:save':  btn?.cancel?.name === "&#x270E" && 'save-text',
                    },
                });
                let changedColor = '';

                // Event listeners for Pickr
                pickr?.on('save', (color, instance) => {
                        const selectedColor = getColor(type||"HEX", color);
                        if (saveEvent) saveEvent(selectedColor);
                        if(btn?.save?.ishide) pickr.hide();
                }).on('change', (color, source, instance) => {
                        changedColor = getColor(type||"HEX", color);
                        if (onChange)onChange(changedColor);
                }).on('cancel', instance => {
                    if (cancelEvent) cancelEvent(changedColor);
                    if(btn?.cancel?.isch){ changedColor = openEyeDropper(pickr)}
                    if(btn?.cancel?.ishide) pickr.hide();
                }).on('clear', instance => {
                         changedColor = openEyeDropper(pickr);
                         instance.cancelClear();
                });
                colorPicker.current.addEventListener("click", () => {
                  pickr.show();
                });
            }
            
        } catch (e) { }
    }, [color,  openEyeDropper]);

 /**
   * Adjusts the position of the color picker dialog to ensure it stays within the viewport.
   * @param {object} e - The click event.
   */
    const handleClick =useCallback((e) => {
      try{
        const colorPickerDialogElement = document.querySelector('.pcr-app.custom-class.visible');
        if (colorPickerDialogElement) {
          const rect = e?.target?.getBoundingClientRect();
          const screenWidth = window?.innerWidth;
          const screenHeight = window?.innerHeight;
        // calculate the center position
          const left = Math.max(0, Math.min(screenWidth - 300, rect?.left + rect?.width / 2 - 150));
          const top = Math.max(0, Math.min(screenHeight - 400, rect.top + rect?.height / 2 - 200));
          colorPickerDialogElement.style.cssText += `
            left: ${left}px !important;
            top: ${top}px !important;
          `;
        }
      }catch(e){}
    },[])
  

    return (
        <div className="simonwep-pickr" onClick={(e)=>handleClick(e)} >
            <div className="color-picker" ref={colorPicker}></div>
           { icon && <FormatColorFillIcon  onClick={() => colorPicker.current.click()} />}
        </div>
    )
}

ColorPick.propTypes = {
    color: PropTypes.string,
    saveEvent: PropTypes.func,
    cancelEvent: PropTypes.func,
    onChange: PropTypes.func,
    btn: PropTypes.object,
    type:PropTypes.string,
  };

export default ColorPick