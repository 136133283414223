import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Datahelper from "../../../utils/DataHelper";
import RichTextEditor from "../RichTextEditor/RichTextEditor";
import PlayerConstants from "../../../utils/PlayerConstants";
import { AddCircle } from "@material-ui/icons";
import UploaderPanel from "../UploaderPanel/UploaderPanel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import AccordionItem from '../../../components/ui/AccordionItem/AccordionItem'; // Assuming your AccordionItem component is in a separate file
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import Checkbox from '@mui/material/Checkbox';
import { FormControlLabel } from '@material-ui/core';
import useToastNotifications from '../../../utils/hooks/useToastNotifications';
import TEMPLATE_MODEL from "../../../utils/TemplateModel";
const RoadRunnerPanel = (
    items,
    handleProperties,
    cename,
    id,
    bg_image,
    target_img,
    // handleCeAssets - This function handles the CE Library asset usage update actions ( previousAsset | newAsset | updatedProps ). 
    handleCeAssets,
    ...rest
) => {
    let prop=TEMPLATE_MODEL[36]?.props;
    const [data, setData] = useState(prop);
    const [expanded, setExpanded] = useState(0);
    const [drag, setDrag] = useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
      };

    const handleUpdateAccList = (e, list) => {
        let updated={...data,items:[...list]}
        setData({...updated});
    }


    /**
     *
     * @param {} value
     * data html string used for description of the component
     */
    const eventHandlers = (value, i) => {
        data.items[i]["question"] = value;
        setData(data);
        handleProperties({ ...data });
    };


    const addAccordion = () => {
        if (data?.items?.length < 0) {
          const newobj = {
            id: data?.items.length + 1,
            img: "*",
            desc: `captions ${data?.items?.length + 1}`,
          };
          data?.items?.push({ id: data?.items?.length + 1, ...newobj });
          setData(data);
          handleProperties({ ...data });
        }
      };

      console.log(items,"addAccordion",prop);
      

  return (
    <div className={`card-prop-main-ui acccordion-item-list`}>
          <div className="acccordion-item-switch"><FormControlLabel label={'Allow reorder'} className="ck-check-box" labelPlacement="end" name="isCharacter" control={<Checkbox checked={drag} onChange={(e) => setDrag(!drag)} />} /> </div>
          <DndProvider backend={HTML5Backend}>
        {data?.items &&
          data?.items?.length > 0 &&
          data?.items?.map((el, index) => {
            return ( <AccordionItem
              className='inner-summary'
              key={index}
              name={`${index+1} Card`}
              index={index}
              el={el}
              data={data?.items}
              listUpdate={handleUpdateAccList}
              expanded={expanded}
              handleAccordionChange={handleChange}
              isDrag={drag}
              summary={{delete: { min: 1 }}}
              dynamicDetailsComponent={<>
               <div className="card-prop-file">
              {/* <UploaderPanel
                accept={"image"}
                fileName={data?.items[index]?.img || ""}
                name={`${id}_${Datahelper.getTime()}-${index + 1}`}
                setUploadedData={(el) => handleMedia(el, index)}
                styles={{
                  position:
                    data?.items[index]["styles"]?.position ||
                    "center  / contain no-repeat",
                  color:
                    data?.items[index]["styles"]?.color ||
                    "rgba(0, 0, 0, 0)",
                }}
                bgChange={(e) => imageStyleChange(e, index)}
                pChange={(e) => imageStyleChange(e, index)}
              /> */}
            </div>
            <div className="card-prop-description">
              <p className="description-text label">Content</p>
              <RichTextEditor
                data={el?.desc || ""}
                eventHandler={(e) => eventHandlers(e, index)}
              />
            </div> </>}
              /> );
          })}
          </DndProvider>
        <div
          className="add-new-slide"
          title={"Add Card"}
          onClick={addAccordion}
        >
          <AddCircle className="add-card" /> Add New Card
        </div>

    </div>
  )
}

export default RoadRunnerPanel