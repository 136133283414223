import React from 'react';
import { Bar } from 'react-chartjs-2';
import PropTypes from 'prop-types'
import ContentTracking from '../../../core/progress/ContentTracking';
import PlayerConstants from '../../../utils/PlayerConstants';
import Instruction from '../../ui/Instruction/Instruction';
import './barchart.scss'

const debouncing = (selector, callback, isLongContent) => {
    const isView = isLongContent || null
    setTimeout(() => {
        let element = document.querySelector(selector);
        let position = element?.getBoundingClientRect();
        const isMobile = (window.matchMedia('max-width: 768px').matches || window.matchMedia('max-height: 450px').matches)
            // checking for partial visibility
        if((position?.top < window.innerHeight && position?.bottom >= 0) && (isMobile || isView)) {
            callback()
        } else {
            // checking whether fully visible
            if(position?.top >= 0 && position?.bottom <= window.innerHeight) {
                callback()
            }
        }
    
    }, 100)
}

const UPDATE_STATUS = PlayerConstants.COMPONENT_CONSTANTS.UPDATE_STATUS
const SAVE_PROGRESS_STATUS = PlayerConstants.COMPONENT_CONSTANTS.SAVE_PROGRESS_STATUS

const options = {
  indexAxis: 'y',
  // Elements options apply to all of the options unless overridden in a dataset
  // In this case, we are setting the border of each horizontal bar to be 2px wide
  elements: {
    bar: {
      borderWidth: 2,
    },
  },
  responsive: true,
  plugins: {
    legend: {
      position: 'right',
    },
    title: {
      display: true,
      text: '',
    },
  },
};

/**
 * 
 * @param {*} handler 
 * @returns function prop will track the chart component
 */

/**
 * 
 * @param {*} labels 
 * @returns used to label the name of the data
 */

/**
 * 
 * @param {*} datasets 
 * @returns the data which which will be marked
 */

/**
 * 
 * @param {*} cename 
 * @returns name of the content component
 */

/**
 * BarChart will display the data with chart
*/
const BarChart = (props) => {
    let {track,handler,labels,datasets,cename} = props
    const [status, setStatus] = React.useState({completed: false, text: PlayerConstants.COMPONENT_CONSTANTS.STATUS_INCOMPLETE})
    const data = {
      labels:labels,
      datasets:datasets,
    };

   /**
     * Manage the component update 
     * progress logic in this method
     * 
     * Update the view status when ever the user interacts
     * Update the progess status ONLY ONCE, when status completes
     * 
     */
    const updateProgress = () => {

      /**
       * View status, will update while navigating to next topic
       */
      track.state = { ...track.state, c:1}
      /**
       * Progress updated as and when its completed
       */
  
      if (!track.status) {
        track.status = 1;
        if (track.status) {
          setStatus({completed: true, text: PlayerConstants.COMPONENT_CONSTANTS.STATUS_COMPLETE})
          handler({ type: SAVE_PROGRESS_STATUS, id: track.id, name:cename })
        }
      }
      /**
       * If anything to be intimated always
       * call this
       */
      handler({ type: UPDATE_STATUS, id: track.id, name:cename })
  
  }

  debouncing('section[name=BarChart]', updateProgress, true)
  

  return <>
  <Instruction isInstruction={false} completed={(track.status === 1 || status.completed)} title={(track.status === 1 || status.completed) ? PlayerConstants.COMPONENT_CONSTANTS.STATUS_COMPLETE : PlayerConstants.COMPONENT_CONSTANTS.STATUS_INCOMPLETE} classText={`${(track.status === 1 || status.completed) && PlayerConstants.COMPONENT_CONSTANTS.COMPLETED_CLASS}`} />
  <div className='header-contain'>
    <h1 className='title'>Horizontal Bar Chart</h1>
  </div>
  <Bar data={data} options={options} />
</>
}

BarChart.propTypes = {
  /** Options available to turn on/off some features */
  options: PropTypes.object,
  /** data object used to render the chart */
  data: PropTypes.object,
  /** Tracking the component progress */
  track:PropTypes.instanceOf(ContentTracking),
  /** Func description for tracking*/
  handler:PropTypes.func
}

export default BarChart;